@import "bricks/wysiwyg";
@import "bricks/link-list-brick";
@import "bricks/image-bar";
@import "bricks/color-teaser";
@import "bricks/icon-list-brick";
@import "bricks/contact-person";
@import "bricks/teaser-module";
@import "bricks/video";
@import "bricks/virtual-tour";
@import "bricks/accordion";
@import "bricks/history";
@import "bricks/news";
@import "bricks/facts";
@import "bricks/teaser";
@import "bricks/bus-slider";

.brick {
    @include media-breakpoint-up(md) {
        padding-bottom: $brick-spacing;
    }

    @include media-breakpoint-down(md) {
        padding-bottom: $brick-spacing-xs;
    }

    &.has-bg-color + .has-bg-color {
        @include negativeMarginTop();

        & + footer {
            @include negativeMarginTop();
        }
    }
}


.b-column-configurator {
    padding-bottom: $brick-spacing-half;

    > .container .container {
        max-width: 100%;
        padding-left: 0;
        padding-right: 0;
    }

    .brick {
        padding-bottom: $default-space;

        &:last-child {
            padding-bottom: 0;
        }
    }

    & + section:not(.b-column-configurator) {
        margin-top: 40px;
    }

    &[class*='bg-color--']:not(.bg-color--none) {
        padding-top: 40px;
        margin-bottom: 40px;
    }
}

.b-bus-search {
    &:last-of-type {
        padding-bottom: 0;
    }

    input[type=number]::-webkit-inner-spin-button,
    input[type=number]::-webkit-outer-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }

    @media (max-width: 1024px) { // ipad landscape
        text-align: center;

        button[type="submit"] {
            margin-top: 20px;
        }
    }
}


