@import "../../../../shared/sass/bricks/link-list-brick";

.b-link-list {

    &__headline {

        h6 {
            color: $red;
        }
    }

    ul {
        @include arrowList;
        padding-top: $default-space;
    }
}