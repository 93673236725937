$brickName: '.b-color-teaser';
#{$brickName} {
    &__wrapper {
        position: relative;
        width: 100%;
    }

    &__color, &__inner {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
    }

    &__color {
        opacity: 1;
        @include transition(opacity ease-in-out 500ms);
        z-index: 1;
        height: 100%;

        &--red { background-color: $red; }
        &--blue { background-color: $blue; }
        &--grey-2 { background-color: $grey-2; }
    }

    &__inner {
        z-index: 2;
        padding: 0 $default-space;
        text-align: center;
        color: $white;
        top: 50%;
        @include translate(0,-50%);

        .h2 {
            margin-bottom: $big-space;
            @media (max-width: 1024px) { // ipad landscape
                font-size: 30px;
            }
        }
    }

    img {
        @include scale(1);
        @include transition(all 2.5s);
        min-width: 100%;
        @include media-breakpoint-down(md) {
            height: auto;
            width: 100%;
        }
    }

    &__item {
        width: 100%;
        position: relative;
        overflow: hidden;
        padding-left: 0;
        padding-right: 0;

        .b-color-teaser__inner > a {
            background-color: transparent;
        }

        &:hover {
            .b-color-teaser__color {
                opacity: 0.6;
                @include transition(opacity ease-in-out 500ms);
            }

            .b-color-teaser__inner > a {
                background-color: $white;
                color: $red;
                &:after {
                    color: $red;
                    @include transition(all 500ms);
                }
            }

            img {
                @include scale(1.3);
                @include transition(all 2.5s);
                width: 100%;
                height: 100%;
                object-fit: cover;
            }
        }

        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
        }

        @include media-breakpoint-up(lg) {
            width: 33.32%;
            float: left;
        }

        @include media-breakpoint-between(md,lg) {
            width: calc(33.33% - 0.5px);
            float: left;
        }

        @include media-breakpoint-down(md) {
            #{$brickName}__inner {
                .h2 {
                    font-size: $font-size-h2-sm * 0.8;
                }
            }
        }

        &.no-image {
            @include media-breakpoint-down(sm) {
                padding-top: 100%;
            }
        }
    }

    &__wrapper {
        &--2 {
            #{$brickName}__item {
                @include media-breakpoint-up(xl) {
                    width: 50%;
                    float: left;
                }

                @include media-breakpoint-between(lg,xl) {
                    width: calc(50% - 0.5px);
                    float: left;
                }

                @include media-breakpoint-between(sm,lg) {
                    width: 50%;
                    float: left;
                }
            }
        }

        &--3 {
            // default behavior
        }

        &--4 {
            #{$brickName}__item {
                @include media-breakpoint-up(xl) {
                    width: 25%;
                    float: left;
                }

                @include media-breakpoint-between(lg,xl) {
                    width: calc(25% - 0.5px);
                    float: left;
                }

                @include media-breakpoint-between(sm,lg) {
                    width: 50%;
                    float: left;
                }

                @include media-breakpoint-between(xl,xxl) {
                    #{$brickName}__inner {
                        .h2 {
                            font-size: $font-size-h2-sm * 0.9;
                        }
                    }
                }

                @include media-breakpoint-down(xl) {
                    #{$brickName}__inner {
                        .h2 {
                            font-size: $font-size-h2-sm * 0.8;
                        }
                    }
                }
            }
        }
    }
}