$name: '.home-stage';

#{$name} {
    &__wrapper {
        position: relative;
        width: 100%;
        height: 100%;
        overflow: hidden;
        @include box-shadow(0 5px 10px 0 rgba($grey-2,0.5));

        &:before {
            content: "";
            background-color: rgba($black, 0.2);
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            z-index: 1;
            @include transition(background-color 500ms);
            pointer-events: none;
        }
    }

    &__discount {
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        z-index: 1;

        &-item {
            background-color: $white;
            border-bottom-left-radius: 10px;
            border-bottom-right-radius: 10px;
            color: $primary;
            font-weight: $font-weight-bold;
            text-transform: uppercase;
            width: 150px;
            padding: 24px 16px;
            float: right;
            text-align: center;

            @include media-breakpoint-down(lg) {
                padding: 15px 10px;
            }
        }

        &-item-percentage {
            font-size: $font-size-xl;
            line-height: $font-size-xl;
            font-style: italic;
            font-weight: $font-weight-bold;

            @include media-breakpoint-down(md) {
                font-size: $font-size-h2-sm;
                line-height: 30px;
            }
        }

        &-item-label {
            font-size: $font-size-m;
            line-height: 32px;
            font-weight: $font-weight-bold;

            @include media-breakpoint-down(md) {
                font-size: $font-size-copy-m-xs;
            }
        }

        &-item-text {
            font-size: $font-size-xs;
            line-height: 20px;
            font-weight: $font-weight-bold;

            @include media-breakpoint-down(md) {
                line-height: 15px;
            }
        }
    }

    &--no-teaser {
        > #{$name}__wrapper {
            min-height: 400px;
        }
    }

    &__image {
        height: 100%;
        position: relative;
    }

    #{$name}__headline, #{$name}__subline, #{$name}__teaser-headline {
        font-weight: 700;
        font-family: $font-family-base;
        line-height: 1;
        margin-bottom: 15px;
        text-transform: uppercase;
    }

    &__text-wrapper {
        padding: 25px;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        text-align: center;
        z-index: 1;
        color: $white;
        width: 100%;

        &--quick-search {
            text-align: left;
        }
    }

    #{$name}__headline, #{$name}__subline {
        font-size: 100px;
        @include media-breakpoint-down(lg) {
            font-size: 70px;
        }

        @include font-h1;
    }

    #{$name}__subline-quick-search {
        @include h6;
    }

    .quick-search-wrapper {
        padding-top: 15px;
    }

    &__teaser {
        display: flex;
        position: relative;
        height: auto;
        z-index: 2;
        flex-wrap: wrap;

        #{$name}__teaser-item {
            width: 25%;
            float: left;
            height: 100%;
            overflow: hidden;
            position: relative;
            border-width: 1px 0.5px; //2px 1px;
            border-style: solid;
            border-color: $white;

            @include media-breakpoint-down(xxl) {
                width: 50%;
            }

            @include media-breakpoint-down(md) {
                width: 100%;
                min-height: 200px;
            }

            &:first-child {
                border-left: 0;
            }

            &:last-child {
                border-right: 0;
            }

            .teaser-image {
                position: absolute;
                object-fit: cover;
                min-width: 100%;
                min-height: 100%;
                @include media-breakpoint-up(md) {
                    top: 50%;
                    left: 50%;
                    -webkit-transform: translate(-50%,-50%) scale(1.01);
                    -ms-transform: translate(-50%,-50%) scale(1.01); // IE9 only
                    -o-transform: translate(-50%,-50%) scale(1.01);
                    transform: translate(-50%,-50%) scale(1.01);
                    @include transition(all 1.5s ease-in-out);
                }
            }

            #{$name}__teaser-inner {
                padding: 25px;
                position: absolute;
                bottom: 16%;
                left: 0;
                width: 100%;
                z-index: 3;
                text-align: center;

                #{$name}__teaser-headline {
                    color: $white;
                    padding-bottom: $default-space;
                    font-size: 50px;

                    @media (max-width: 1920px) {
                        font-size: 40px;
                    }

                    @include media-breakpoint-down(lg) {
                        font-size: 30px;
                    }
                }

                @include media-breakpoint-down(sm){
                    .btn-teaser { font-size: 12px; }
                }
            }

            .ie-objectfit-fallback {
                @include media-breakpoint-up(md) {
                    @include transition(all 1.5s ease-in-out);
                }
            }

            &:before {
                content: "";
                background-color: rgba($black, 0.2);
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                z-index: 2;
                @include transition(background-color 500ms);
            }

            &:hover {
                .ie-objectfit-fallback {
                    @include media-breakpoint-up(md) {
                        @include transition(all 1.5s ease-in-out);
                        transform: scale(2);
                        -moz-transform: scale(1.2);
                        -webkit-transform: scale(1.2);
                        -o-transform: scale(1.2);
                        -ms-transform: scale(1.2); /* IE 9 */
                        -ms-filter: "progid:DXImageTransform.Microsoft.Matrix(M11=1.2, M12=1.2, M21=1.2, M22=1.2, SizingMethod='auto expand')"; /* IE8 */
                        filter: progid:DXImageTransform.Microsoft.Matrix(M11=1.2, M12=0, M21=0, M22=1.2, SizingMethod='auto expand'); /* IE6 and 7 */
                    }
                }

                &:before {
                    content:"";
                    background-color: rgba($black, 0);
                    @include transition(background-color 500ms);
                }
            }
        }

        &--two {
            #{$name}__teaser-item {
                width: 50%;
            }
        }

        &--one {
            #{$name}__teaser-item {
                width: 100%;
            }
        }
    }

    /**
     * Quick Search
     */
    &--quick-search {
        #{$name} {
            &__headline {
                font-size: $font-size-50px;
                line-height: $line-height-60px;

                @include media-breakpoint-down(sm) {
                    font-size: $font-size-40px;
                    line-height: $line-height-40px;
                }
            }

            &__subline-quick-search {
                text-transform: uppercase;
            }

            .quick-search-wrapper {
                margin-top: 48px;

                @include media-breakpoint-down(sm) {
                    margin-top: 32px;
                }
            }
        }
    }
}

@keyframes fadeIn {
    from {opacity: 0;}
    to {opacity: 1;}
}

@keyframes fadeOut {
    from {opacity: 1;}
    to {opacity: 0;}
}

@keyframes slidenavAnimation {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}
@-webkit-keyframes slidenavAnimation {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}

@keyframes cubeAnimation {
    0% {
        transform: translateZ(-20px) rotateX(-40deg);
    }
    90% {
        transform: translateZ(-20px) rotateX(-90deg);
    }

    99% {
        opacity: 0;
    }
    100% {
        transform: translateZ(-20px) rotateX(0);
        opacity: 1;
    }
}

@keyframes cubeAnimationBack {
    0% {
        transform: rotateX(0deg);
        //top: 0;
    }
    10% {
        transform: rotateX(-40deg);
        //top: 10px;
    }
    /*45% {
      opacity: 1;
      transform: translateZ(-20px) rotateX(-88deg);
    }*/
    50% {
        //opacity: 0;
        transform: rotateX(-90deg);
        //top: 30px;
    }
    /*55% {
      transform: translateZ(-20px) rotateX(88deg);
      opacity: 1;
    }*/
    90% {
        transform: rotateX(40deg);
        //top: -10px;
    }
    100% {
        transform: rotateX(0deg);
        //top: 0;
    }
}