.b-link-list {

    &__wrapper--left-right {
        position: relative;
        padding-bottom: 70px;

        @include media-breakpoint-down(sm) {
            padding-bottom: 40px;
            margin-bottom: 50px;
        }

        .button-wrapper {
            position: absolute;
            bottom: 0;
        }
    }

}