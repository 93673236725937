.b-accordion {
    .list-group {
        &__accordion-item {
            border-bottom: 1px solid $grey-0;
            padding: $default-space 0;
            margin-top: 0;
            border-radius: 0;
            @include box-shadow(none);

            &__header {
                > button {
                    position: relative;
                    padding-left: 20px;
                    display: inline-block;
                    font-weight: $font-weight-bold;

                    &:hover, &:focus {
                        color: $red;
                    }

                    &:before {
                        content: "\f146";
                        font-family: $fontfamily-fontawesome;
                        font-weight: $fontweight-fontawesome;
                        display: block;
                        position: absolute;
                        left: 0;
                        top: 0;
                    }

                    &[aria-expanded=false] {
                        &:before {
                            content: "\f0fe";
                        }
                    }
                }
            }

            &__body {
                padding: 5px 0 0 20px;

                > p:last-child {
                    margin-bottom: 0;
                }
            }
        }
    }
}