@import "bootstrap";
$name: '.footer';

footer {
    border-top: 8px solid $grey-0;
}

#{$name} {
    &__top {
        #{$name}__logo-list {
            &--img {
                padding: 15px 0;
            }
            @media (max-width: 1024px) {
                    &--wrapper {
                    overflow: hidden;
                }
            }
        }
    }
    &__main {
        background-color: #f5f5f5;
        #{$name}__columns {
             #{$name}__column-headline-link {
                 text-decoration: none;
                 color: #575756;
            }
            #{$name}__column-links {
                border-top: 1px solid #e2e1e1;
                padding-top: 15px;
            }
            #{$name}__column-link {
                padding-left: 10px;
                color: #575756;
                text-decoration: none;
                line-height: 1.4em;
                position: relative;
                display: inline-block;
                &:before {
                    content: "\203A";
                    position: absolute;
                    left: 0;
                    top: 0;
                    color: #575756;
                    font-weight: bold;
                    font-size: 1.3em;
                    line-height: 1em;
                    transition: left ease-in-out 250ms;
                }
                &:hover {
                    &:before {
                        left: 3px;
                        transition: left ease-in-out 250ms;
                    }
                }
            }
        }
    }
    &__bottom {
        .container {
            @include media-breakpoint-down(md) {
                padding-bottom: 50px;
            }
        }
        background-color: #e63055;
        &-copyright {
            color: #fff;
        }
        &-cta > a {
            background-color: #e63055;
            border: 1px solid #fff;
            border-radius: 4px;
            color: #fff;
            text-decoration: none;
            font-weight: 700;
            padding: 6px 30px;
            cursor: pointer;
            text-align: center;
            vertical-align: middle;
        }
        &-legal-links {
            a {
                color: #fff;
                text-decoration: none;
            }
            #{$name}__bottom-divider {
                color: #fff;
            }
        }
    }
}