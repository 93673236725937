@import "../../../../shared/sass/bricks/news";

.b-news {
    font-family: $font-family-base;

    &__headline {
        color: $grey-3;
        font-weight: $font-weight-extra-bold;
    }

    &__item {
        &__body {
            padding: 32px;

            &__link {
                a {
                    color: $red;
                }
            }

            &__title {
                font-weight: $font-weight-extra-bold;
                @include media-breakpoint-down(lg) {
                    font-size: $fontsize-m1;
                }
            }
        }

    }

    &__button {
        color: white;
        display: block;
        font-weight: bold;
        padding: 10px 25px;
        margin: 30px auto 80px;
        text-transform: uppercase;
    }
}