/**
 * Bootstrap Overrides
 */
$white: #fff;
$black: #1c1c1b;
$blue: #9FD7F6;
$blue-light: #9FD7F6;
$blue-dark: #1C3A69;
$green: #acd333;
$grey-0: #f5f5f5;
$grey-1: #e2e1e1;
$grey: $grey-1;
$grey-2: #868686;
$grey-3: #575756;
$grey-4: #8c8c8c;
$muted: #D5D5D5;
$muted-2: #D4D4D4;
$yellow: #ffb300;
$red: #E63055;
$body-color: $grey-3;
$nav-link-color: $blue-dark;
$primary: $red;// $blue-dark;
$color-blue: #1d70b7;

/*
  only put custom variables here,
  change values of standard bootstrap variables in bootstrap's variables.less
*/
$imagepath: "/static/img";
$fontpath: "/../fonts";
$icon-font-path: "/static/dist/vendor/bootstrap-sass-3.3.7/assets/fonts/bootstrap/";
$svgpath: "/static/svg";

/* === Colors === */
$grey-1: #e2e1e1;
// REMOVE THIS IN THE END - BUT PREVENT USING THIS VARIALBES, USE BOOTSTRAP ONES
//$color-white: #fff;
//$color-black: #1c1c1b;
//$color-blue: #1d70b7;
//$color-blue-dark: #1C3A69;
//$color-green: #acd333;
//$color-grey-0: #f5f5f5;
//$color-grey-1: #e2e1e1;
//$color-grey: $color-grey-1;
//$color-grey-2: #868686;
//$color-grey-3: #575756;
//$color-yellow: #ffb300;
//$color-red: #e63055;
$text-color: $grey-3;

/* === Color Rebranding === */
$new-red: #E63055;
$new-blue: #9FD7F6;
$new-grey: #868686;

/* === Fonts === */
$font-family-base: 'Source Sans Pro', sans-serif;
$font-family-special: 'Permanent Marker', cursive;

$fontfamily-fontawesome: 'Font Awesome 6 Pro';
$fontfamily-fontawesome-brands: 'Font Awesome 5 Brands';
$fontweight-fontawesome: 900;

$font-weight-light: 300;
$font-weight-regular: 400;
$font-weight-semibold: 600;
$font-weight-bold: 700;

$font-size-base: 1rem; //16px

$font-size-xxs: 10px;
$font-size-xs: 14px;
$font-size-s: $font-size-base;
$font-size-m: 30px;
$font-size-m2: 20px;
$font-size-l: 40px;
$font-size-xl: 50px;
$font-size-xxl: 100px;

$font-factor-sm: 0.65;
$font-factor-xs: 0.5;
$font-factor-xxs: 0.3;

$font-size-h1: $font-size-xxl;
$font-size-h1-sm: $font-size-xxl * $font-factor-sm;
$font-size-h1-xs: $font-size-xxl * $font-factor-xs;
$font-size-h1-xxs: $font-size-xxl * $font-factor-xxs;
$font-size-h2: $font-size-xl;
$font-size-h2-sm: $font-size-xl * $font-factor-sm;
$font-size-h2-xs: $font-size-xl * $font-factor-xs;
$font-size-h3: $font-size-l;
$font-size-h3-sm: $font-size-l * $font-factor-sm;
$font-size-h3-xs: $font-size-l * $font-factor-xs;
$font-size-h4: $font-size-m;
$font-size-h4-xs: $font-size-m * 0.65;
$font-size-h5: 24px;
$font-size-h6: $font-size-s;
$font-size-h7: 20px;

$font-size-copy-m-xs: $font-size-m * 0.85;
$font-size-copy-m-xxs: $font-size-m * 0.67;

/* === Borders === */


/* === Buttons === */


/* === Panels === */


/* === Sizes === */
$screen-md-min: 541px;
$big-container-width: 1800px;

$default-space: 15px;
$big-space: $default-space * 2;
$small-space: $default-space / 2;

$header-height: 82px; // TODO
$header-height-sm: 74px;

$brick-spacing: 40px;
$brick-spacing-half: $brick-spacing / 2;

$brick-spacing-xs: 60px;

/* === Booker === */
$progress-bar-circle-size-big: 30px;
$progress-bar-circle-size-small: 10px;
$progress-bar-line-height: 80px;
$progress-bar-line-padding: 10px;
$progress-bar-line-width: 1px;

$booker-field-height: 40px;
$booker-icon-padding-left: 40px;
// home stage
$home-stage-slider-height: 68%;

// screen sizes
$screen-xs-max: 575px;