.b-image-bar {
    // overwrite bootstrap 5 styling
    &.brick {
        padding-bottom: 0;
    }

    .image-bar__wrapper {
        display: flex;
        flex-direction: row;

        &:before, &:after {
            content: "";
            display: table;
        }

        &:after {
            clear: both;
        }

        &.no-space-top {
            margin-top: -$brick-spacing-half;
        }

        .image-bar__item {
            position: relative;
            width: 16.66667%;
            float: left;
            overflow: hidden;

            img {
                height: 100%;
                object-fit: cover;
                width: 100%;
            }


            &.image-bar-col2 { width: 16.66667%; }
            &.image-bar-col20percent { width: 20%; }
            &.image-bar-col3 { width: 25%; }
            &.image-bar-col4 { width: 33.33333%; }
            &.image-bar-col6 { width: 50%; }
            &.image-bar-col12 { width: 100%; }


            img {
                @include scale(1.01);
                @include transition(all 2s ease-in-out);
            }

            &:hover {
                img {
                    @media (min-width: $screen-md-min) {
                        @include scale(1.3);
                        @include transition(all 2s ease-in-out);
                    }
                }
            }
        }
    }
}

// check in future - do we really need that always? added now a check, if it is used as header stage
header + .b-image-bar.brick {
    padding-bottom: $brick-spacing;
}