.b-contact-person {
    &__outer-wrapper, &__wrapper {
        display: block;
        max-width: 360px;
        margin: 0 auto;
        border: 1px solid $grey-0;
        min-height: 530px;

        video {
            max-width: 100%;
        }

        &__image-video.show-play-button {
            position: relative;
            cursor: pointer;

            &:before {
                content: "\f144";
                font-family: $fontfamily-fontawesome;
                font-weight: $fontweight-fontawesome;
                position: absolute;
                top: 50%;
                left: 50%;
                @include translate(-50%,-50%);
                color: $red;
                font-size: 50px;
                pointer-events: none;
            }
        }
    }

    &__inner {
        padding: $big-space;

        h6 {
            padding-bottom: $default-space;
            border-bottom: 1px solid $grey-0;
            margin-bottom: 20px;
        }

        .name-title {
            padding-bottom: $big-space;
        }

        h5 {
            margin-bottom: 5px;
        }

        .contact-data {
            position: relative;
            padding-left: $big-space + 5px;

            a {
                display: block;
                color: $text-color;
                hyphens: auto;

                &:hover {
                    color: $red;
                }
            }

            i.fa {
                position: absolute;
                left: 0;
                top: 3px;
            }

            &.contact-data--phone-fax {
                padding-bottom: 20px;
            }
        }
    }

    @include media-breakpoint-down(md) {
        &__inner {
            padding: $default-space;

            .contact-data {
                padding-left: 25px;
            }
        }
    }
}