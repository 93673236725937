#headerStage {
    position: relative;
    width: 100%;
    height: 100%;
    height: 100vh;
    overflow: hidden;
    border: 10px solid $white;
    box-shadow: 0 10px 15px 0 rgba($grey-2, 0.5);
    min-height: 600px;
    max-height: 1000px;

    @include media-breakpoint-up(md) {
        margin-bottom: $brick-spacing;
    }
    @include media-breakpoint-down(md) {
        margin-bottom: $brick-spacing-xs;
    }

    &:before {
        content: "";
        @include headerStageOverlay;
    }

    .ie-objectfit-fallback {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        overflow: hidden;
    }

    .headerStage-image {
        position: absolute;
        top: 50%;
        left: 50%;
        object-fit: cover;
        -webkit-transform: translate(-50%, -50%) scale(1.01);
        -ms-transform: translate(-50%, -50%) scale(1.01); // IE9 only
        -o-transform: translate(-50%, -50%) scale(1.01);
        transform: translate(-50%, -50%) scale(1.01);
        @include transition(all 1.5s ease-in-out);
        min-width: 100%;
        min-height: 100%;
    }

    .headerStage__inner {
        position: absolute;
        width: 100%;
        top: 50%;
        color: $white;
        text-align: center;
        @include translate(0, -50%);
        z-index: 2;

        .h1 {
            text-transform: uppercase;
        }

        input {
            background-color: transparent;
            border-top: 0;
            border-left: 0;
            border-right: 0;
            border-bottom: 1px solid $white;
            width: 100px;
            padding: 0 $default-space;
            text-align: center;
        }

        .price-calculate {
            padding-top: $default-space;
            @include h4;
            text-transform: uppercase;

            input[type=number]::-webkit-inner-spin-button,
            input[type=number]::-webkit-outer-spin-button {
                -webkit-appearance: none;
                margin: 0;
            }

            button {
                margin-left: $default-space;

                @include media-breakpoint-down(md) {
                    margin-left: 0;
                    margin-top: $default-space * 2;
                }
            }
        }
    }

    & + .area-facts {
        //@include negativeMarginTop();
    }

    // header stage Slider
    &.sliderSnippet {
        .sliderSnippet__slider.swiper-slider {
            visibility: hidden;

            &.swiper-initialized {
                visibility: visible;
            }
        }

        .sliderSnippet__slider:not(.swiper-slider) {
            position: relative;
            width: 100%;
            height: 100%;
        }

        .sliderSnippet__outer-wrapper {
            position: relative;
            width: 100%;
            height: 100%;

            .swiper-slider, .swiper-slide, .swiper-slide > div, .swiper-list, .swiper-track {
                height: 100%;
            }

            .sliderSnippet__item {
                height: 100%;
                width: 100%;
                position: relative;
            }
        }

        .sliderSnippet__image {
            position: absolute;
            top: 50%;
            left: 50%;
            max-width: 2000px;
            min-width: 100%;
            min-height: 100%;
            width: auto;
            @include translate(-50%, -50%);
        }

        &--reduced {
            height: 72vh;

            @media screen and (max-width: 1920px) and (min-width: 1381px) {
                height: 57vh;
            }

            @media screen and (max-width: 1380px) {
                height: 53vh;
            }

            &:before {
                display: none;
            }

            .sliderSnippet {
                &__item {
                    &:before {
                        content: '';
                        @include headerStageOverlay;
                    }

                    &--no-gradient {
                        &:before {
                            display: none;
                        }
                    }
                }

                &__image {

                }

                &__arrow {
                    position: absolute;
                    z-index: 2;
                    top: 50%;
                    transform: translateY(-50%);
                    font-size: 40px;
                    color: $white;
                    cursor: pointer;

                    &--left {
                        left: 65px;
                    }

                    &--right {
                        right: 65px;
                    }

                    @include media-breakpoint-between(md, lg) {
                        &--left {
                            left: 40px;
                        }

                        &--right {
                            right: 40px;
                        }
                    }

                    @media (max-width: 767px) and (min-width: 576px) {
                        font-size: 35px;

                        &--left {
                            left: 25px;
                        }

                        &--right {
                            right: 25px;
                        }
                    }

                    @include media-breakpoint-down(sm) {
                        &--left {
                            left: 15px;
                        }

                        &--right {
                            right: 15px;
                        }
                    }
                }

                &__arrows {

                }
            }
        }
    }
}