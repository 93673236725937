.row {
    &.row--flex {
        @include flex-parent();

        &.justify-center {
            @include justify-content();
        }
    }

    &.row--no-margin {
        margin-left: 0;
        margin-right: 0;
    }
}

[class^="col-"] {
    &.col--justify-center {
        @include justify-content();
    }
}

.col--flex {
    @include flex-child();
}

.align-items--center {
    align-items: center !important;
    -webkit-align-items: center !important;
}

.flex-container {
    @include flexbox;
    flex-wrap: wrap;
    -webkit-flex-wrap: wrap;
    padding-left: 0;
    padding-right: 0;

    .flex-article {
        float: left;
        padding: 15px;
        display: -webkit-box;
        display: -webkit-flex;
        display: -ms-flexbox;
        display: flex;

        @include media-breakpoint-up(sm) {
            // one article
            &:first-child:nth-last-child(1) { @include flexbasis(100%); }

            // two articles
            &:first-child:nth-last-child(2),
            &:first-child:nth-last-child(2) ~ article { @include flexbasis(50%); }

            // three articles
            &:first-child:nth-last-child(3),
            &:first-child:nth-last-child(3) ~ article { @include flexbasis(33.33%); }

            &:first-child:nth-last-child(4),
            &:first-child:nth-last-child(4) ~ article { @include flexbasis(50%); }
        }

        // four articles
        @include media-breakpoint-up(md) {
            &:first-child:nth-last-child(4),
            &:first-child:nth-last-child(4) ~ article { @include flexbasis(25%); }
        }

        // all fullwidth mobile
        @include flexbasis(100%);
    }
}