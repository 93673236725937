$name: '.b-bus-slider';

#{$name} {
    padding-bottom: 0 !important;
    &__outer-wrapper {
        position:relative;
        overflow: hidden;

        &.is-header-slider {
            margin-top: -$brick-spacing-half;
        }

        #{$name} {
            &__background {
                position: absolute;
                width: 100%;
                padding-bottom: 54.5%;
                overflow: hidden;
                pointer-events: none;

                > img {
                    position: absolute;
                    top: 0;
                    left: 0;
                }
            }

            &__thumbnails {
                text-align: center;
                @include media-breakpoint-up(md) {
                    position: absolute;
                    top: $brick-spacing-half;
                    left: 0;
                    width: 100%;
                    z-index: 10;

                    @include media-breakpoint-up(md) {
                        top: 5px;
                    }
                }

                @include media-breakpoint-down(sm) {
                    margin-bottom: $default-space;
                }

                .flex-container {
                    justify-content: center;
                }

                #{$name}__bus-thumbnail {
                    display: inline-block;
                    cursor: pointer;
                    max-width: 115px;

                    @include media-breakpoint-down(sm) {
                        max-width: 80px;

                        #{$name}__bus-subtitle {
                            padding: 0 5px;
                            @include font-copy-xs;
                            font-weight: $font-weight-light;
                        }
                    }
                }
            }

            &__thumbnail-slider-wrapper {
                position: relative;
            }

            &__slider {
                @include transition(all 500ms);

                #{$name}__item img {
                    pointer-events: none;
                }

                .bus-slider-img {
                    @include media-breakpoint-up(sm) {
                        height: 800px;
                        width: 100%;
                        object-fit: contain;
                    }
                }

                &:not(:first-child) {
                    display: none;
                }

                .navigation-wrapper {
                    position: relative;

                    @include media-breakpoint-down(sm) {
                        height: 50px;
                    }

                    .swiper-button-next, .swiper-button-prev {
                        cursor: pointer;
                        position: absolute;
                        top: -70px;

                        @include media-breakpoint-up(lg) {
                            top: -120px
                        }

                        @include media-breakpoint-down(sm) {
                            top: 0;
                        }

                        &:after {
                            //font-size: 17px;
                            color: $grey-2;
                            font-weight: bold;
                        }
                    }
                    .swiper-button-next {
                        right: 40%;
                    }
                    .swiper-button-prev {
                        left: 40%;
                    }
                }
            }

            &__busInformation-wrapper {
                @include media-breakpoint-down(lg) {
                    position: relative;
                    width: 100%;
                    left: 0;
                    height: 100%;
                    pointer-events: none;
                    top: 0;

                    > .container {
                        position: relative;
                        height: 100%;

                        @include media-breakpoint-between(sm, md) {
                            width: 100%;
                        }
                    }
                }

                #{$name}__busInformation {
                    background-color: $white;
                    padding: $default-space;
                    pointer-events: all;
                    border: 1px solid $grey-0;

                    @include media-breakpoint-up(md) {
                        position: absolute;
                        right: 115px; //$default-space;
                        bottom: 90px; //$brick-space;
                        opacity: 0.6;
                        @include transition(opacity 500ms);
                        width: 310px;
                        max-width: 100%;
                        z-index: 10;

                        &:hover {
                            opacity: 1;
                            @include transition(opacity 500ms);
                        }
                    }

                    @include media-breakpoint-down(xxl) {
                        right: auto;
                        left: 15px;
                        bottom: 20px;
                        opacity: 1;
                        width: auto;
                    }

                    @include media-breakpoint-down(md){
                        margin-top: $default-space;
                        left: auto;
                        bottom: auto;
                        position: relative;
                    }

                    h6 {
                        margin-top: $default-space;
                        margin-bottom: $big-space;
                        color: $grey-2;
                    }

                    #{$name}__information-item {
                        display: block;
                        line-height: 30px;
                        padding: 10px 0;
                        border-top: 1px solid $grey-0;

                        &:last-child {
                            border-bottom: 1px solid $grey-0;
                        }

                        span.icon {
                            float: left;
                            width: $big-space;
                            height: $big-space;
                        }

                        span.label {
                            font-weight: $font-weight-bold;
                            display: inline-block;
                            padding: 0 $default-space;
                            color: $grey-3;
                        }
                    }

                    a.btn {
                        margin: $default-space;
                        width: calc(100% - #{$big-space});
                    }
                }

                // because first child is bus
                &:not(:nth-child(2)) {
                    display: none;
                }
            }
        }
    }
}