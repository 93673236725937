.b-virtual-tour {
    &-wrapper {
        position: relative;
        padding-bottom: 56.25%; // 16:9
        @include media-breakpoint-down(xs) {
            padding-bottom: 100%;
        }
    }

    &-iframe {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        border: 0;
    }
}