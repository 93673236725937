.b-news {
    .hidden-items {
        display: none;
    }

    &__headline {
        margin-top: 80px;
        margin-bottom: 60px;
        justify-content: center;
        text-align: center;
        font-size: 50px;
        font-weight: bold;
    }

    &__item {
        background: #FFFFFF 0 0 no-repeat padding-box;
        box-shadow: 0 20px 40px #5757561C;
        opacity: 1;
        border: none;
        border-radius: 0;
        --bs-border-radius: 0;
        margin-bottom: 30px;

        &__image-wrapper {
            position: relative;
            overflow: hidden;
            padding-bottom: 61.5%;
            picture {
                img {
                    position: absolute;
                    min-height: 100%;
                    min-width: 100%;
                }
            }
        }


        &__body {
            padding: 32px;

            &__title {
                margin-bottom: 8px;
                font-size: 32px;
                font-weight: bold;
            }

            p {
                font-size: 1rem;
            }

            &__link {
                font-weight: bold;
                font-size: 1rem;
                margin-top: 32px;
                justify-content: center;

                .pointer {
                    margin-right: 5px;
                    &:before {
                        font-weight: bold;
                    }
                }

                a {
                    text-decoration: none;
                    text-transform: uppercase;
                }
            }
        }

    }

    &__button {
        display: block;
        font-weight: bold;
        font-size: 1rem;
        background-color: white;
        border: 1px solid black;
        padding: 10px 25px;
        margin: 30px auto 80px;
        text-transform: uppercase;
    }
    .swiper-button-next, .swiper-button-prev {
        top: 70%;
        padding-left: 5px;
        &:after {
            color: $red;
            font-size: 30px;
            font-weight: bold;
        }
    }
    .swiper-pagination-bullet.swiper-pagination-bullet-active {
        background-color: $red;
    }

}

.news-detail-stage {
    position: relative;
    padding: 0;
    margin-top: -140px;
    height: 840px;
    @include media-breakpoint-down(lg) {
        height: 540px;
    }
    @include media-breakpoint-down(md) {
        height: 390px;
    }
    &__image{
        img {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            object-fit: cover;
            height: 800px;
            @include media-breakpoint-down(lg) {
                height: 500px;
            }
            @include media-breakpoint-down(md) {
                height: 350px;
            }
        }
    }
    &__callout {
        position: absolute;
        bottom: 70px;
        right: 70px;

        @include media-breakpoint-down(md) {
            bottom: 50px;
            right: 50px;
        }
    }
}