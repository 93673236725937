$full-height: 1000px;
$small-teaser-height: $full-height / 3;
$small-teaser-height-x: $small-teaser-height * 0.75;
$big-teaser-height: $small-teaser-height * 2;
$big-teaser-height-xs: $big-teaser-height * 0.6;

$name: '.b-teaser-module';

#{$name} {
    &__element {

    }

    &__wrapper {
        box-shadow: 0 0 20px 0 rgba($grey-2,0.5);
        margin: 0 auto;
        height: auto;
        min-height: $full-height;

        position: relative;
        width: 100%;
        overflow: hidden;
        border: 10px solid $white;
        @include box-shadow(0 5px 10px 0 rgba($grey-2,0.5));


        @include media-breakpoint-up(md) {
            width: 80%;
            margin-bottom: $brick-spacing;
        }

        @include media-breakpoint-down(md) {
            margin-bottom: $brick-spacing-xs;
        }

        &.teaserModule__wrapper--count-rows-3 {
            min-height: 950px;
        }

        &.teaserModule__wrapper--count-rows-5 {
            min-height: 1850px;
        }

        &:before {
            background: transparent;
            content: "";
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            z-index: 1;
            @include transition(background-color 500ms);
            pointer-events: none;
        }
    }

    &__side {
        position: relative;
        min-height: $full-height;
        height: auto;

        @media (max-width: 1650px) {
            min-height: 500px;
        }

        @media(min-width: 1651px) {
            width: calc(50% - 1px);
            float: left;

            &:first-child {
                margin-right: 1px;
            }

            &:last-child {
                margin-left: 1px;
            }
        }

        &--fullwidth {
            width: 100%;
            min-height: 0;
        }

        &:after, &:before {
            content: " ";
            display: table;
        }

        &:after {
            clear: both;
        }
    }

    &__teaser-height {
        &--two-of-3 {
            height: $big-teaser-height;

            @include media-breakpoint-down(md) {
                height: $big-teaser-height-xs;
            }

            #{$name}__teaser-item {
                #{$name}__teaser-inner {
                    @include media-breakpoint-up(sm) {
                        width: 50%;
                        @include translate(50%,-50%);
                    }
                }
            }
        }

        &--one-of-3 {
            height: $small-teaser-height;

            @include media-breakpoint-down(md) {
                height: $small-teaser-height-x;
            }
        }

        &--two-of-5 {
            height: calc((100% / 5) * 2);

            #{$name}__teaser-item {
                #{$name}__teaser-inner {
                    @include media-breakpoint-up(md) {
                        width: 60%;
                        @include translate(40%,-50%);
                    }
                }
            }
        }

        &--two-of-4 {
            height: calc((100% / 4) * 2);
        }

        &--one-of-4 {
            height: 0;
        }

        &--one-of-5 {
            height: calc(100% / 5);
        }
    }

    &__teaser-item {
        border-width: 1px;

        &[aria-expanded=true] {
            &:before {
                background-color: transparent;
            }

            #{$name}__teaser-inner {
                opacity: 0;
            }
        }

        &:hover {
            &:before {
                background-color: rgba($blue,0.85);
                @include transition(background-color 500ms);
            }

            &:not(&--has_link) {
                #{$name}__teaser-inner {
                    #{$name}__teaser-headline {
                        display: none;
                    }
                    #{$name}__teaser-description {
                        display: block;
                    }
                }
            }

            @include media-breakpoint-up(md) {
                #{$name}__teaser-inner {
                    #{$name}__teaser-headline {
                        display: block;
                    }
                    #{$name}__teaser-description {
                        display: block;
                    }
                }
            }
        }
    }

    &__teaser-inner {
        padding: 0 $default-space;

        #{$name}__teaser-description {
            color: $white;
            display: none;
        }

        @media (min-width: 1025px) {
            > .h2 {
                font-size: 40px;
            }
        }
    }

    &__collapse-content {
        .brick {
            &:first-of-type {
                padding-top: $brick-spacing-half;
            }

            padding-bottom: $brick-spacing-half;
        }
    }
}

.pimcore_editable.pimcore_editable_snippet {
    .x-panel-body.x-panel-body-default.x-noborder-trbl {
        width: 100% !important;
    }
}


#{$name} {


    &--no-teaser {
        > #{$name}__wrapper {
            min-height: 400px;
        }
    }

    &__slider-outer-wrapper {
        position: relative;
        //overflow: hidden;
        height: $home-stage-slider-height;

        // if fullheight and no teaser
        #{$name}__slider--fullheight {
            height: 100%;
        }

        #{$name}__slider-text-wrapper {
            position: absolute;
            bottom: 0;
            width: 100%;
            top: 45%;
            color: $white;
            text-align: center;
            z-index: 6;

            .h1 {
                text-transform: uppercase;
                position: relative;

                .inner {
                    white-space: nowrap;
                    position: absolute;
                    left: 50%;
                    top: 0;
                    @include translate(-50%,0);

                    @include media-breakpoint-between(sm,md){
                        font-size: 40px;
                    }

                    @include media-breakpoint-down(sm){
                        font-size: 30px;
                    }

                    @media (max-width: 500px) {
                        font-size: 20px;
                    }

                    &.fallback {
                        position: static;
                        @include translate(0,0);
                    }
                }
            }

            select {
                -webkit-appearance: none;
                appearance: none;
                background-color: transparent;
                border: none;
                text-transform: uppercase;
            }
        }
    }

    &__slider {
        position: relative;
        overflow: hidden;
        min-height: 100%;
        visibility: hidden;
        height: 100%;

        .ie-objectfit-fallback {
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            overflow: hidden;

            #{$name}__slider-item-image {
                position: absolute;
                top: 50%;
                left: 50%;
                object-fit: cover;
                -webkit-transform : translate(-50%,-50%) scale(1.01);
                -ms-transform: translate(-50%,-50%) scale(1.01); // IE9 only
                -o-transform: translate(-50%,-50%) scale(1.01);
                transform: translate(-50%,-50%) scale(1.01);
                @include transition(all 1.5s ease-in-out);
                min-width: 100%;
                min-height: 100%;
            }
        }

        &.slick-initialized {
            visibility: visible;
        }

        &:not(.slick-slider) {
            position: relative;
            width: 100%;
            height: 100%;
        }

        .slick-slider, .slick-slide, .slick-slide > div, .slick-list, .slick-track {
            height: 100%;
        }

        #{$name}__slider-item {
            height: 100%;
            width: 100%;
            position: relative;
        }
    }

    &__teaser {
        position: relative;
        //height: calc(100% - #{$home-stage-slider-height});
        z-index: 2;

        &:first-child {
            border-left: 0;
        }

        &:last-child {
            border-right: 0;
        }

        #{$name}__teaser-item {
            width: 25%;
            float: left;
            height: 100%;
            overflow: hidden;
            position: relative;
            border-width: 1px 0.5px; //2px 1px;
            border-style: solid;
            border-color: $white;

            .ie-objectfit-fallback {
                position: absolute;
                top: 0;
                left: 0;
                right: 0;
                bottom: 0;
            }

            .teaser-image {
                position: absolute;
                top: 50%;
                left: 50%;
                object-fit: cover;
                -webkit-transform: translate(-50%,-50%) scale(1.01);
                -ms-transform: translate(-50%,-50%) scale(1.01); // IE9 only
                -o-transform: translate(-50%,-50%) scale(1.01);
                transform: translate(-50%,-50%) scale(1.01);
                @include transition(all 1.5s ease-in-out);
                min-width: 100%;
                min-height: 100%;
            }

            #{$name}__teaser-inner {
                position: absolute;
                top: 50%;
                @include translate(0,-50%);
                left: 0;
                width: 100%;
                z-index: 3;
                text-align: center;

                #{$name}__teaser-headline {
                    text-transform: uppercase;
                    color: $white;
                    padding-bottom: $default-space;

                    @media (max-width: 1024px) { // ipad landscape
                        font-size: 30px;
                    }

                    @include media-breakpoint-down(sm){
                        font-size: 20px;
                    }
                }

                @include media-breakpoint-down(sm){
                    .btn-teaser { font-size: 12px; }
                }
            }

            .ie-objectfit-fallback {
                @include transition(all 1.5s ease-in-out);
            }

            &:before {
                content: "";
                background-color: rgba($black, 0.2);
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                z-index: 2;
                @include transition(background-color 500ms);
            }

            &:hover {
                .ie-objectfit-fallback {
                    @include transition(all 1.5s ease-in-out);
                    transform: scale(2);
                    -moz-transform: scale(1.2);
                    -webkit-transform: scale(1.2);
                    -o-transform: scale(1.2);
                    -ms-transform: scale(1.2); /* IE 9 */
                    -ms-filter: "progid:DXImageTransform.Microsoft.Matrix(M11=1.2, M12=1.2, M21=1.2, M22=1.2, SizingMethod='auto expand')"; /* IE8 */
                    filter: progid:DXImageTransform.Microsoft.Matrix(M11=1.2, M12=0, M21=0, M22=1.2, SizingMethod='auto expand'); /* IE6 and 7 */
                }

                &:before {
                    content:"";
                    background-color: rgba($color-blue, 0.85);
                    @include transition(background-color 500ms);
                }
            }
        }

        &--two {
            #{$name}__teaser-item {
                width: 50%;
            }
        }

        &--one {
            #{$name}__teaser-item {
                width: 100%;
            }
        }
    }

    // BOOTSTRAP SELECT
    .bootstrap-select {
        width: auto;
        pointer-events: all;
        text-transform: uppercase;

        .dropdown-toggle.btn-default {
            background-color: transparent;
            padding: 0 ($big-space * 3) 0 0;

            @include media-breakpoint-between(md,lg) {
                padding-right: 20px;
            }

            @include media-breakpoint-down(md) {
                padding-right: 20px;
            }

            > span.filter-option {
                padding: 0 10px 5px 10px;
                color: $white;
                @include font-h1;
                text-transform: uppercase;
                top: 0;
                position: relative;

                //old
                @include transition(all 500ms);
                @include rotateX(0deg);

                @include media-breakpoint-between(md,lg) {
                    font-size: 40px;
                }

                @include media-breakpoint-down(md){
                    font-size: 30px;
                }

                @media (max-width: 500px) {
                    font-size: 20px;
                }
            }

            span.bs-caret {
                &:after {
                    content: "\f107";
                    position: absolute;
                    top: 10px;
                    @include font-h1;
                    color: $white;
                    right: 0;
                    font-family: $fontfamily-fontawesome;
                    font-weight: $fontweight-fontawesome;


                    @include media-breakpoint-between(sm,md){
                        top: 15px;
                        font-size: 40px;
                    }

                    @include media-breakpoint-down(sm){
                        top: 10px;
                        font-size: 30px;
                    }

                    @media (max-width: 500px) {
                        top: 5px;
                        font-size: 20px;
                    }
                }

                .caret {
                    display: none;
                }
            }
        }

        .dropdown-menu:not(.inner) {
            border-top: 1px solid $white;
            background-color: rgba($black, 0.9);
        }

        .dropdown-menu {
            &.inner {
                background-color: transparent;
            }

            li {
                a {
                    color: $white;
                    font-weight: $font-weight-bold;
                    font-size: 2em;

                    @include media-breakpoint-down(md) {
                        font-size: 1.1em;
                    }
                }

                a:hover {
                    background-color: rgba($white, 0.7);
                    color: $black;
                }

                &.selected a {
                    background: transparent;
                    color: $white;
                }
            }
        }

        // rotate
        &.rotate-x {
            .dropdown-toggle.btn-default {
                > span.filter-option {
                    @include rotateX(90deg);
                    @include transition(all 500ms);
                    top: 30px;
                    position: relative;
                }
            }
        }

        &.rotate-x-top {
            .dropdown-toggle.btn-default {
                > span.filter-option {
                    position: relative;
                    animation: cubeAnimationBack 2s;
                }
            }
        }

        // animations dropdown menu open
        > .dropdown-menu {
            animation-name: slidenavAnimation;
            animation-duration:.5s;
            animation-iteration-count: 1;
            animation-timing-function: ease;
            animation-fill-mode: forwards;

            -webkit-animation-name: slidenavAnimation;
            -webkit-animation-duration:.5s;
            -webkit-animation-iteration-count: 1;
            -webkit-animation-timing-function: ease;
            -webkit-animation-fill-mode: forwards;

            -moz-animation-name: slidenavAnimation;
            -moz-animation-duration:.5s;
            -moz-animation-iteration-count: 1;
            -moz-animation-timing-function: ease;
            -moz-animation-fill-mode: forwards;
        }
    }
}

@keyframes slidenavAnimation {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}
@-webkit-keyframes slidenavAnimation {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}

@keyframes cubeAnimation {
    0% {
        transform: translateZ(-20px) rotateX(-40deg);
    }
    90% {
        transform: translateZ(-20px) rotateX(-90deg);
    }

    99% {
        opacity: 0;
    }
    100% {
        transform: translateZ(-20px) rotateX(0);
        opacity: 1;
    }
}

@keyframes cubeAnimationBack {
    0% {
        transform: rotateX(0deg);
        //top: 0;
    }
    10% {
        transform: rotateX(-40deg);
        //top: 10px;
    }
    /*45% {
      opacity: 1;
      transform: translateZ(-20px) rotateX(-88deg);
    }*/
    50% {
        //opacity: 0;
        transform: rotateX(-90deg);
        //top: 30px;
    }
    /*55% {
      transform: translateZ(-20px) rotateX(88deg);
      opacity: 1;
    }*/
    90% {
        transform: rotateX(40deg);
        //top: -10px;
    }
    100% {
        transform: rotateX(0deg);
        //top: 0;
    }
}