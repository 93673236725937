@import "../../../../shared/sass/bricks/wysiwyg";

.b-wysiwyg {
    &__wrapper {
        padding: 40px 0 10px;
        &.bg-gray {
            background-color: $grey-0;
        }
        .copy-m {
            img {
                @include media-breakpoint-down(sm) {
                    width: 100% !important;
                }
            }
        }
    }
}

.text-blue {
    color: $blue !important;
}

.text-red {
    color: $red !important;;
}