.news-detail {
    margin-top: 70px;
    @include media-breakpoint-down(sm) {
        margin-top: 40px;
    }

    &__headline {
        font-size: 3.25rem;
    }

    &__content {
        font-size: 1rem;
    }

}