
@mixin make-font($size: null, $weight: null, $family: $font-family-base, $line-height: null, $color: null) {
    @if $color {
        color: $color
    }
    @if $size {
        font-size: $size
    }
    @if $weight {
        font-weight: $weight
    }
    @if $family {
        font-family: $family
    }
    @if $line-height {
        line-height: $line-height
    }
}

@mixin font-copy {
    @include make-font($font-size-base, $font-weight-regular);
}

@mixin font-copy-xxs {
    @include make-font($font-size-xxs, $font-weight-semibold);
}

@mixin font-copy-xs {
    @include make-font($font-size-xs, $font-weight-bold);
}

@mixin font-copy-s {
    @include make-font($font-size-xs, $font-weight-regular);
}

@mixin font-copy-m {
    @include make-font($font-size-m, $font-weight-regular);

    //@media (max-width: $grid-breakpoints(sm)) and (min-width: 501px) { font-size: $font-size-copy-m-xs; }
    //@media (max-width: $screen-xs-max) and (min-width: 501px) { font-size: $font-size-copy-m-xs; }
    @media (max-width: 500px) { font-size: $font-size-copy-m-xxs; }
    @media (max-width: 1200px) { font-size: $fontsize-m1; }
}

@mixin font-copy-m2 {
    @include make-font($font-size-m2, $font-weight-regular, $font-family-base, 22px);
}

@mixin font-special-s {
    @include make-font($font-size-s, $font-weight-regular, $font-family-special);
}

@mixin font-special-m {
    @include make-font($font-size-m, $font-weight-regular, $font-family-special);
}

@mixin font-menu {
    @include make-font($font-size-s, $font-weight-semibold);
}

@mixin font-link-btn {
    @include make-font($font-size-s, $font-weight-bold);
}

@mixin font-h1 {
    @include h1;
}

@mixin h1 {
    @include make-font($font-size-h1, $font-weight-extra-bold, $font-family-base, 1);

    @media (max-width: 1024px) { font-size: $font-size-h1-sm; } // ipad landscape
    @include media-breakpoint-down(md) { font-size: $font-size-h1-xs; }
    @media (max-width: 500px) { font-size: $font-size-h1-xxs; }
}

@mixin h2 {
    @include make-font($font-size-h2, $font-weight-extra-bold, $font-family-base, 1);

    @include media-breakpoint-between(md,lg) { font-size: $font-size-h2-sm; }
    @include media-breakpoint-down(md) { font-size: $font-size-h2-xs; }
}

@mixin h3 {
    @include make-font($font-size-h3, $font-weight-extra-bold, $font-family-base, 1);
    margin-bottom: $small-space;

    @include media-breakpoint-between(md,lg) { font-size: $font-size-h3-sm; }
    @include media-breakpoint-down(md) { font-size: $font-size-h3-xs; }
}

@mixin h4 {
    @include make-font($font-size-h4, $font-weight-extra-bold, $font-family-base, 1);

    @include media-breakpoint-down(md) { font-size: $font-size-h4-xs; }
}

@mixin h5 {
    @include make-font($font-size-h5, $font-weight-extra-bold, $font-family-base, 1);
}

@mixin h6 {
    @include make-font($font-size-h6, $font-weight-extra-bold, $font-family-base, 1);
}

@mixin h7 {
    @include make-font($font-size-h7, $font-weight-extra-bold, $font-family-base, 1);
}

@mixin link-button {
    font-weight: $font-weight-extra-bold;
}