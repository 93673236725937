.b-teaser {

    &__wrapper {
        padding-top: $brick-spacing-half;
        &.bg-gray {
            background-color: $grey-0;
        }
    }

    &__content {
        .b-teaser__wrapper {
            &--centered {
                display: flex;
                flex-direction: column;
                align-items: center;

                .b-teaser__content-text {
                    font-size: $font-size-m;
                    font-weight: $font-weight-light;

                    @include media-breakpoint-down(lg) {
                        font-size: $font-size-m;
                    }
                    @include media-breakpoint-down(sm) {
                        font-size: 20px;
                    }
                }
            }
            .b-teaser__content-headline {
                font-size: $font-size-l;
                font-weight: $font-weight-extra-bold;
                line-height: 1;
                margin-bottom: 7.5px;
                text-transform: uppercase;

                @include media-breakpoint-down(sm) {
                    font-size: 32px;
                    hyphens: auto;
                    -webkit-hyphens: auto;
                    text-align: center;

                }
            }
            .b-teaser__content-subline {
                color: $grey-2;
                font-size: $font-size-base;
                font-weight: $font-weight-extra-bold;
                margin-bottom: 16px;
                letter-spacing: 0.05em;
            }
            .b-teaser__content-text {
                margin: 0 0 11px;
            }

            .b-teaser__content-image {
                margin: 50px 0 50px;
            }
        }
        .b-teaser__content-subline--left {
            line-height: 2em;

            @include media-breakpoint-down(sm) {
                hyphens: auto;
                -webkit-hyphens: auto;
                line-height: 1.5em;
                letter-spacing: 0;
            }
        }
    }
}