$brickName: '.b-facts';

#{$brickName} {
    #{$brickName}-wrapper {
        margin-bottom: 15px;
        color: $red;
        background-color: $blue-light;

        #{$brickName}__headline {
            display: flex;
            justify-content: center;
            padding-top: 80px;
            font-weight: $font-weight-bold;
            font-size: 3.125rem;

            @include media-breakpoint-down(sm) {
                font-size: 1.75rem;
            }
        }

        #{$brickName}__subline {
            display: flex;
            justify-content: center;
            margin-bottom: 80px;
            font-weight: $font-weight-bold;
            font-size: $font-size-base;
        }

        #{$brickName}__item {
            margin: 0 40px 0 40px;

            @include media-breakpoint-down(lg) {
                margin: 0 20px 0 20px;
            }
            @include media-breakpoint-down(sm) {
                margin: 0;
            }

            &__value {
                position: relative;

                .value {
                    margin-bottom: 4px;
                    display: flex;
                    justify-content: center;
                    padding-top: 45px;
                    font-size: 9.25rem;
                    font-weight: $font-weight-bold;
                    -webkit-text-fill-color: transparent;
                    -webkit-text-stroke-width: 2px;
                    -webkit-text-stroke-color: $red;

                    @include media-breakpoint-down(xl) {
                        font-size: 7.5rem;
                    }
                    @include media-breakpoint-down(lg) {
                        font-size: 6rem;
                        padding-top: 35px;
                    }
                }

                .value-shadow {
                    position: absolute;
                    width: inherit;
                    height: inherit;
                    top: 50%;
                    left: 50%;
                    transform: translate(-50%, -50%);
                    font-size: 13.25rem;
                    font-weight: $font-weight-bold;
                    opacity: 0.15;

                    @include media-breakpoint-down(xl) {
                        font-size: 11.25rem;
                    }
                    @include media-breakpoint-down(lg) {
                        font-size: 9rem;
                    }
                }
            }

            &__suffix {
                display: flex;
                justify-content: center;
                font-weight: $font-weight-bold;
                font-size: $font-size-base;
                margin-bottom: 80px;

                @include media-breakpoint-down(md) {
                    margin-bottom: 30px;
                }
            }

            &__subline {
                margin-bottom: 16px;
                font-weight: $font-weight-bold;
                font-size: $font-size-base;
            }

            &__text {
                font-size: $font-size-base;

                @include media-breakpoint-down(md) {
                    margin-bottom: 50px;
                }
            }
        }
    }
}
