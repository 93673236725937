/* Header */
header {
    padding: $big-space 0;
    background-color: transparent;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 100;
    @include transition(all 500ms);

    @include hamburger-static;
    @include hamburger-sticky;

    .hamburger-inner, .hamburger-inner:after, .hamburger-inner:before {
        width: 40px;
        height: 4px;
        border-radius: 4px;
        position: absolute;
        background-color: $white;
        @include transition(background-color 500ms);
    }

    &.sticky, &.always-sticky {
        background-color: $white;
        padding: $default-space 0;
        @include transition(all 500ms);
        @include box-shadow(0 5px 10px 0 rgba($grey-2, 0.5));

        @include media-breakpoint-down(sm) {
            padding-top: 0;
            padding-bottom: 0;
        }

        .hamburger-inner, .hamburger-inner:after, .hamburger-inner:before {
            background-color: $black;
        }
    }

    > .big-container > .row--flex {
        -webkit-flex-flow: row;
        -moz-flex-flow: row;
        -ms-flex-flow: row;
        flex-flow: row;
    }

    .pull-right {
        float: right !important;
    }

    // logo
    .logo {
        > a {
            flex: 1 1 auto;
            -webkit-flex: 1 1 auto;
        }

        @include flexbox;
        align-items: center;
        -webkit-align-items: center;

        .logo-nonsticky {
            display: block;
            svg, svg * {
                fill: white;
                @include transition(all 500ms);
                @include media-breakpoint-down(md) {
                    max-width: 100px;
                }
            }
            @include media-breakpoint-down(md) {
                width: 100px;
            }
        }

        .logo-sticky {
            display: none;
        }

        // IE fix
        svg {
            max-height: 52px;
        }
    }

    // main navigation
    nav.main-navigation {
        ul {
            list-style: none;
            padding-left: 0;
            margin-bottom: 0;
            text-align: right;

            li {
                display: inline-block;
                text-transform: uppercase;
                font-weight: $font-weight-extra-bold;
                padding: 25px $default-space 5px $default-space;
                font-size: $font-size-xs;

                a {
                    color: $white;
                    text-decoration: none;

                    &:hover, &:focus {
                        color: $red;
                    }
                }

                &:last-child { padding-right: 0; }
                &:first-child { padding-left: 0; }

                @include media-breakpoint-between(sm, md) {
                    font-size: 0.93em;
                    padding: $default-space 12px;
                }

            }
        }
    }


    &.sticky, &.always-sticky {
        background-color: $white;
        padding: $default-space 0;
        @include transition(all 500ms);
        @include box-shadow(0 5px 10px 0 rgba($grey-2,0.5));

        @include media-breakpoint-down(sm) {
            padding-top: 0;
            padding-bottom: 0;
        }




        // logo
        .logo {
            padding: 0;
            .logo-nonsticky {
                svg, svg * {
                    fill: $body-color;
                    @include transition(all 500ms);
                }
            }

            .logo-nonsticky {
                display: none;
            }

            .logo-sticky {
                display: block;
                @include media-breakpoint-down(sm) {
                    height: 30px;
                    max-width: 200px;
                    svg {
                        max-height: 30px;
                    }
                }
            }

            > a {
                display: block;
                @include media-breakpoint-down(lg) {
                    padding-left: $default-space;
                }
            }
        }

        // main navigation
        nav.main-navigation {
            ul {
                li {
                    a {
                        color: $body-color;

                        &:hover {
                            color: $red;
                        }
                    }
                }
            }
        }
    }

    #mobile-navigation {
        display: none;
    }

    .need-bus-wrapper {
        position: relative;
        top: -38px;
        right: 0;
        transition: all 500ms;

        .need-bus {
            position: absolute;
            top: 20px;
            right: 20px;

            .btn {
                color: $white;
                font-size: 12px;
                font-weight: $font-weight-extra-bold;

                &:hover, &:focus {
                    color: $black;
                    background-color: $muted-2;
                    border-color: $grey-4;
                }
            }

            ul {
                border-radius: unset;
            }
        }
    }

    // language-switch
    .language-switch {
        position: absolute;
        top: 0;
        right: 250px;
        background-color: $white;
        color: $black;

        &.big-container {
            position: relative;
            top: -43px;
            right: 0;
            @include transition(all 500ms);
        }

        .btn-default {
            background-color: $red;
            color: $white;
            @include font-copy-xs;
            text-transform: uppercase;
            padding: 3px 10px;
        }

        ul {
            list-style: none;
            margin: 0;
            display: inline-block;
            text-transform: uppercase;
            font-weight: $font-weight-extra-bold;
            padding: 22px $default-space 5px $default-space;

            li {
                display: inline-block;
                padding: 0 3px;
                width: 20px;
                line-height: 1;

                + li {
                    border-left: 1px solid $grey-3;
                    //margin-left: 15px;
                }

                a, span {
                    text-decoration-line: none;
                    color: $grey-3;
                    @include transition(all 500ms);
                }
                a {
                    font-weight: 300;
                    &:hover, &:focus {
                        color: $red;
                    }
                }

                &:last-child { padding-right: 0; }
                &:first-child { padding-left: 0; }

                @include media-breakpoint-between(md, lg) {
                    font-size: 0.93em;
                }
            }
        }
    }
    &.sticky,
    &.always-sticky {
        .language-switch {
            &.big-container {
                top: -38px;
            }

            ul {
                li {
                    a, span {
                        text-decoration-line: none;
                        color: $grey-3;
                    }
                }
            }
        }

    }
}

#mobile-navigation {
    .mm-navbars-bottom {
        .socialShareBar__item-text {
            display: none;
        }
    }

    .mm-navbar {
        .language-switch {
            width: 100%;
            display: inline-block;
            ul {
                display: inline-block;
                list-style: none;
                padding: 0;
                li {
                    float: left;
                    padding: 0 5px;
                    border-right: 1px solid $grey-1;
                    &:last-child {
                        border-right: 0;
                    }
                    a, span {
                        font-size: 20px;
                    }
                    span {
                        font-weight: 700;
                    }
                }
            }
        }
    }
}