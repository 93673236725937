/* === BEM Mixins === */

/// Block Element
/// @access public
/// @param {String} $element - Element's name
@mixin element($element, $more: null) {
    #{bemEls($element, $more)} {
        @content;
    }
}

/// Block Modifier
/// @access public
/// @param {String} $modifier - Modifier's name
@mixin modifier($modifier, $more: null) {
    #{bemMods($modifier, $more)} {
        @content;
    }
}

/// @alias element
@mixin e($element, $more: null) {
    @include element($element, $more)  {
        @content;
    }
}

/// @alias modifier
@mixin m($modifier, $more: null) {
    @include modifier($modifier, $more) {
        @content;
    }
}

/* === Text Mixins === */

@mixin text-uppercase {
    text-transform: uppercase;
}

// Button sizes
@mixin button-size($padding-vertical, $padding-horizontal, $font-size, $line-height, $border-radius) {
    padding: $padding-vertical $padding-horizontal $padding-vertical;
    font-size: $font-size;
    line-height: $line-height;
    border-radius: $border-radius;
}

// quadratic images
@mixin quadratic-image($max-size: 100%) {
    padding-top: 100%;
    position: relative;

    img {
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        margin: auto;
        max-width: $max-size;
        max-height: $max-size;
    }
}

// transforms
@mixin transform($transform) {
    -webkit-transform: $transform;
    -ms-transform: $transform; // IE9 only
    -o-transform: $transform;
    transform: $transform;
}

@mixin translate-rotate($x, $y, $degrees) {
    -webkit-transform: translate($x, $y) rotate($degrees);
    -ms-transform: translate($x, $y) rotate($degrees); // IE9 only
    -o-transform: translate($x, $y) rotate($degrees);
    transform: translate($x, $y) rotate($degrees);
}

@mixin translate($x, $y) {
    -webkit-transform: translate($x, $y);
    -ms-transform: translate($x, $y); // IE9 only
    -o-transform: translate($x, $y);
    transform: translate($x, $y);
}

// browser support flexbox
@mixin flex-parent($flex-flow: row wrap, $align-items: stretch, $justify-content: space-between) {
    display: -webkit-box;      /* OLD - iOS 6-, Safari 3.1-6 */
    display: -moz-box;         /* OLD - Firefox 19- (buggy but mostly works) */
    display: -ms-flexbox;      /* TWEENER - IE 10 */
    display: -webkit-flex;     /* NEW - Chrome */
    display: flex;             /* NEW, Spec - Opera 12.1, Firefox 20+ */

    -webkit-flex-flow: $flex-flow;
    -moz-flex-flow: $flex-flow;
    -ms-flex-flow: $flex-flow;
    flex-flow: $flex-flow;

    -webkit-align-items: $align-items;
    -moz-align-items: $align-items;
    -ms-align-items: $align-items;
    align-items: $align-items;

    -webkit-justify-content: $justify-content;
    -moz-justify-content: $justify-content;
    -ms-justify-content: $justify-content;
    justify-content: $justify-content;
}

@mixin flex-child() {

}

// font sizes flow mixin
@mixin flow-font-size($flow-variable, $flow-index: auto){
    $flow-length: length($flow-variable);

    @if $flow-index == "auto" {
        @include flow-font-size($flow-variable, 1);
        @include media-breakpoint-up(sm) { @include flow-font-size($flow-variable, 2); }
        @include media-breakpoint-up(md) { @include flow-font-size($flow-variable, 3); }
        @include media-breakpoint-up(lg) { @include flow-font-size($flow-variable, 4); }
    } @else if $flow-index >= $flow-length {
        font-size: nth($flow-variable, $flow-length);
    } @else {
        font-size: nth($flow-variable, $flow-index);
    }
}

// Icon
@mixin icon-fill($fill: #ffffff) {
    fill: $fill;
}

@mixin icon($size, $fill: null) {
    @if $size == tiny {
        width: 10px;
        height: 10px;
    } @else if $size == small {
        width: 16px;
        height: 16px;
    } @else {
        width: $size;
        height: $size;
    }
    @if $fill {
        @include icon-fill($fill);
    }

    svg {
        width: 100%;
        height: 100%;
    }
}

@mixin background-color($background-color: $white, $selector: white) {
    background-color: $background-color;
    &.bg-#{$selector}--transparent {
        background-color: rgba($background-color, 0.9);
    }
}

@mixin justify-content($justify: center) {
    justify-content: $justify;
    -webkit-justify-content: $justify;
    -moz-justify-content: $justify;
    -ms-justify-content: $justify;
}

@mixin flexbox($flex-direction: row) {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    //overflow: auto;
    flex-direction: $flex-direction;
    -webkit-flex-direction: $flex-direction;
}

@mixin flexbasis($percent) {
    flex-basis: $percent;
    -webkit-flex-basis: calc(#{$percent} - 1px);
    max-width: $percent;
}

@mixin btnColors($color-1, $color-2)
{
    background-color: $color-1;
    border-color: $color-2;

    &:hover {
        background-color: $color-1;
        color: $color-2;
    }
}

@mixin arrowList {
    padding-left: 0;
    list-style: none;
    margin-bottom: 0;

    li {
        padding-bottom: 5px;

        a {
            display: inline-block;
            position: relative;
            padding-left: $default-space - 5px;
            color: $body-color;
            line-height: 1.4em;

            &:before {
                content: "\203A";
                position: absolute;
                left: 0;
                top: 0;
                color: $body-color;
                line-height: 1em;
                font-size: 1.3em;
                font-weight: bold;
                @include transition(left ease-in-out 250ms);
            }

            &:hover {
                &:before {
                    left: 3px;
                    @include transition(left ease-in-out 250ms);
                }
            }
        }
    }

    @include media-breakpoint-down(md) {
        padding-bottom: $default-space;
    }
}

@mixin list {
    padding-left: 0;
    list-style: none;
    margin-bottom: 0;

    li {
        display: inline-block;
        padding: 0 $default-space;

        &:first-child { padding-left: 0; }
        &:last-child { padding-right: 0; }

        a {
            font-weight: $font-weight-bold;
            text-transform: uppercase;
            display: inline-block;

            &:after {
                content: "\f101";
                display: inline-block;
                font-family: $fontfamily-fontawesome;
                padding-left: $default-space;
                font-weight: $fontweight-fontawesome;
            }

            &:hover {
                color: $body-color;
            }
        }
    }

    @include media-breakpoint-down(md) {
        text-align: center;

        li {
            padding: 0;

            &:not(:last-child) {
                padding-bottom: 5px;
            }
        }
    }
}

@mixin greenList {
    @include list;
    li {
        a {
            color: $green;
        }
    }

}

@mixin redList {
    @include list;
    li {
        a {
            color: $red;
        }
    }
}

@mixin faListClear {
    padding-left: 0;
    list-style: none;
    margin-bottom: 0;

    li {
        padding-bottom: 5px;
        position: relative;
        padding-left: $default-space - 5px;
        color: $body-color;
        line-height: 1.4em;

        &:before {
            content: "\203A";
            position: absolute;
            left: 0;
            top: 0;
            color: $body-color;
            line-height: 1em;
            font-size: 1.3em;
            font-weight: bold;
            @include transition(left ease-in-out 250ms);
        }
    }

    @include media-breakpoint-down(md) {
        padding-bottom: $default-space;
    }
}

@mixin negativeMarginTop {
    @include media-breakpoint-up(md) { margin-top: -$brick-spacing; }
    @include media-breakpoint-down(md) { margin-top: -$brick-spacing-xs; }
}

@mixin floatingPlaceholder($border: 1px solid $grey-1) {
    position:relative;
    border: 1px solid $grey-0;

    &.has-error {
        border: 1px solid $red;
    }

    input, label {
        @include font-menu;
    }

    input {
        padding: 6px 12px;
        width: 100%;
        height: $booker-field-height;
        border: none;
        color: $grey-2;

        &:focus, &:not([value=""]) {
            & + label {
                top: 0;
                @include transform(translate(-15px, -50%) scale(0.75,0.75) rotateY(0));
            }
        }
    }
    label {
        display:block;
        position:absolute;
        top: 50%;
        left: 20px;
        margin-bottom: 0;
        padding: 0 15px;
        background-color: white;
        color: $grey-2;
        z-index:1;
        @include transform-origin(0 0);
        @include transition(transform 160ms, top 200ms);
        @include transform(translate(-15px, -50%) scale(1,1) rotateY(0));
        pointer-events: none;
    }

    &.small-label {
        label {
            font-size: 12px;
        }
    }

    &.with-icon {
        label {
            left: $booker-icon-padding-left;
        }
    }

    &.autocomplete {
        &.focused {
            label {
                top: 0;
                @include transform(translate(-15px, -50%) scale(0.75,0.75) rotateY(0));
            }
        }
    }

    .rdtOpen {
        label {
            top: 0;
            @include transform(translate(-15px, -50%) scale(0.75,0.75) rotateY(0));
        }
    }

    &.small-input {
        input {
            height: 25px;
        }
    }

    &.borderless {
        border: none;
        input {
            border: none;
            border-radius: 0;
            @include box-shadow(none);
        }
    }
}

@mixin headerStageOverlay {
    background-color: rgba($black, 0.5);
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
    @include transition(background-color 500ms);
    pointer-events: none;
}

@mixin rotateX($degrees) {
    -webkit-transform: rotateX($degrees);
    -ms-transform: rotateX($degrees); // IE9 only
    -o-transform: rotateX($degrees);
    transform: rotateX($degrees);
}

@mixin rotateY($degrees) {
    -webkit-transform: rotateY($degrees);
    -ms-transform: rotateY($degrees); // IE9 only
    -o-transform: rotateY($degrees);
    transform: rotateY($degrees);
}