.b-icon-list {
    &__item {
        position: relative;
        padding: 10px 0;

        span.icon {
            width: 20px;
            height: 20px;
            position: absolute;
            display: block;
            left: 0;
            top: 10px;
        }
    }

    &__text {
        padding-left: 30px;
        cursor: pointer;

        .small-hl {
            font-weight: $font-weight-light;
        }
    }

    &__copytext-wrapper {
        padding-left: 30px;
        border-bottom: 1px solid $grey-1;
    }

    h6 {
        color: $red;
    }
}