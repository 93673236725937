$icon-size: 50px;

.socialShareBar {
    position: fixed;
    right: $big-space;
    top: 50%;
    @include translate(0,-50%);
    z-index: 11;

    &__item {
        line-height: $icon-size;
        color: $white;
        font-weight: $font-weight-bold;
        margin: 5px 0;

        a {
            color: $white;
        }

        &__text {
            position: absolute;
            right: $icon-size + 5px;
            background-color: $red;
            width: auto;
            white-space: nowrap;
            padding: 0 $default-space;
            visibility: hidden;
            pointer-events: none;
            opacity: 0;
            @include transition(opacity 500ms ease-in-out);
            height: 0;
            overflow: hidden;
        }

        .spacer {
            width: 5px;
            height: $icon-size;
            background-color: transparent;
            position: absolute;
            right: $icon-size;
        }

        &__icon {
            width: $icon-size;
            height: $icon-size;
            background-color: $red;
            text-align: center;
            display: block;
            font-size: 1.2em;
            cursor: pointer;

            i {
                line-height: $icon-size;
            }
        }

        &:hover, &.open {
            .socialShareBar__item__text {
                visibility: visible;
                pointer-events: all;
                opacity: 1;
                @include transition(opacity 500ms ease-in-out);
                height: $icon-size;
                overflow: auto;
            }
        }

        // share
        &.socialShareBar__share {
            .socialShareBar__item__icon {
                background-color: $grey-2;
            }

            .socialShareBar__item__text {
                background: transparent;
                padding-left: 0;
                padding-right: 0;
                display: flex;
                justify-content: center;
                list-style: none;
                gap: 4px;

                > a {
                    display: inline-block;
                    width: $icon-size;
                    height: $icon-size;
                    line-height: $icon-size;
                    text-align: center;
                    justify-content: center;

                    &.twitter {
                        background-color: #0084b4;
                    }

                    &.facebook {
                        background-color: #3b5998;
                    }

                    &.xing {
                        background-color: #126567;
                    }

                    &.linkedin {
                        background-color: #0e76a8;
                    }

                    i {
                        line-height: unset;
                    }
                }
            }
        }
    }

    @include media-breakpoint-down(sm) {
        display: none;
    }

}