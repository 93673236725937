$brickName: '.b-history';

#{$brickName} {
    position: relative;


    .container {
        padding: 80px 0 0 0;
        position: relative;
        overflow: hidden;
    }

    &__heading {
        text-align: center;
        padding-bottom: 80px;
        @include media-breakpoint-down(lg) {
            min-height: 170px;
        }
        @include media-breakpoint-down(md) {
            min-height: 162px;
        }

        #{$brickName}__subline {
            margin-bottom: 0;
            text-transform: uppercase;
            color: $grey-2;
            font-size: $font-size-base;
            font-weight: bold;

        }
        #{$brickName}__headline {
            font-size: 50px;
        }
    }

    &__content-wrapper {
        position: relative;
    }

    &__timeline {
        position: relative;
        width: 80%;
        height: 16px;
        margin: 0 auto;

        &-dots-wrapper {
            width: 327px;
            .dot{
                cursor: pointer;
                border-radius: 100%;
                width: 16px;
                height: 16px;
                background-color: $grey-2;
                content: "";
                z-index: 10;
                margin: 0 auto;
            }
            &.swiper-slide-active {
                .dot {
                    background-color: $red;
                }
            }
        }

        &::after {
            content: '';
            width: 100%;
            height: 0;
            border-bottom: 1px $grey-2 solid;
            top: 50%;
            transform: translateY(-50%);
            position: absolute;
        }
    }

    &__content {
        width: 80%;
        margin: 0 auto;
        overflow: hidden;

        .swiper-wrapper {
            height: unset;
        }

        &-entry {
            display: flex;
            flex-direction: column;
            align-items: center;
            font-weight: $font-weight-regular;
            &-year {
                color: $grey-2;
                margin: 0 auto 32px auto;
                font-size: 100px;

                @include media-breakpoint-down(xl) {
                    font-size: 80px;
                }
                @include media-breakpoint-down(lg) {
                    font-size: 60px;
                }
            }
            &-description {
                color: $grey-3;
                font-size: $font-size-base;
                margin-top: 32px;
                max-width: 327px;
            }

            &-image {
                position: relative;
                max-width: 327px;

                .icon {
                    position: absolute;
                    top: 16px;
                    right: 16px;
                    width: 19px;
                    height: 19px;

                    > svg {
                        fill: #fff;
                    }
                }
            }

            &.swiper-slide-active {
                .b-history__content-entry-year {
                    color: $red;
                }
            }
        }
    }

    &__prev-next {
        .swiper-button-next, .swiper-button-prev {
            cursor: pointer;
            position: absolute;
            top: 8px;

            &:after {
                font-size: 17px;
                color: $grey-2;
                font-weight: bold;
            }
        }

        .swiper-button-next {
            right: 5%;
            @include media-breakpoint-down(lg) {
                right: 3%;
            }
            @include media-breakpoint-down(sm) {
                right: 0;
            }
        }

        .swiper-button-prev {
            left: 5%;
            @include media-breakpoint-down(lg) {
                left: 3%;
            }
            @include media-breakpoint-down(sm) {
                left: 0;
            }
        }
    }

    &__start {
        &:before {
            position: absolute;
            border-radius: 100%;
            top: 0;
            width: 16px;
            height: 16px;
            left: calc(10% - 8px);
            background-color: $grey-2;
            content: "";
            z-index: 10;
        }

        &.muted:before {
            background-color: $muted;
        }
    }

    &__end {
        &:after {
            position: absolute;
            border-radius: 100%;
            top: 0;
            width: 16px;
            height: 16px;
            right: calc(10% - 8px);
            background-color: $grey-2;
            content: "";
            z-index: 10;
        }

        &.muted:after {
            background-color: $muted;
        }
    }

}
