.brick.b-video {
    .video-wrapper {
        text-align: center;
    }

    video {
        max-width: 100%;
    }
}

section {
    text-align: unset;
}