$homeStage_SliderHeight_md: 70%;
$homeStage_TeaserHeight_md: 100% - $homeStage_SliderHeight_md;

$homeStage_SliderHeight_sm: 45%;
$homeStage_TeaserHeight_sm: 100% - $homeStage_SliderHeight_sm;

#homeStage {
    @include media-breakpoint-down(lg) {
        .homeStage__slider-outer-wrapper {
            height: $homeStage_SliderHeight_md;
        }

        .homeStage__teaser {
            height: $homeStage_TeaserHeight_md;
        }
    }

    @include media-breakpoint-down(md) {
        homeStage__slider-outer-wrapper {
            height: $homeStage_SliderHeight_sm;

            .homeStage__slider-text-wrapper {
                top: $homeStage_SliderHeight_sm - 5%;
            }
        }

        .homeStage__teaser {
            height: $homeStage_TeaserHeight_sm;

            .homeStage__teaser-item {
                width: 50%;
                height: 50%;
            }
        }
    }


    @include media-breakpoint-down(sm) {
        .homeStage__slider-outer-wrapper {
            height: 35%;
        }
        .homeStage__teaser {
            height: 65%;
        }
    }
}