/*-------------------------------------------------------------------------*/
/*	Global */
/*-------------------------------------------------------------------------*/

body {
    font-size: $font-size-base;
    line-height: $line-height-base;
}

#siteWrapper {
    max-width: 2200px;
    margin: 0 auto;
    position: relative;
    padding-top: 80px;

    &.booking-page {
        background-color: $grey-0;

        header {
            z-index: 20; // because of modal on bpc
        }

        & + footer {
            padding-bottom: 130px;
        }
    }
}


h1, .h1, h2, .h2, h3, .h3, h4, .h4, h5, .h5, h6, .h6 {
    //font-size: inherit;
    //line-height: inherit;
    margin-top: 0;
    text-transform: uppercase;
}

h1, .h1 {
    @include font-h1();
    margin-bottom: $default-space;
}

h2, .h2 {
    @include h2;
    margin-bottom: $default-space;
}

h3, .h3 {
    @include h3;
}

h4, .h4 {
    @include h4;
    margin-bottom: $default-space;
}

h5, .h5 {
    @include h5;
}

h6, .h6 {
    @include h6;
    margin-bottom: $default-space;
    letter-spacing:0.05em;
}

.h7 {
    @include h7;
}

* {
    &:focus, &:hover {
        outline: none !important;
    }
}

a {
    @include transition(ease all .3s);
    &:focus, &:hover {
        text-decoration: none;
    }
}

hr {
    margin-top: 15px;
    margin-bottom: 15px;
    border-width: 2px;
}

.spacing {
    &.spacing--bottom {
        margin-bottom: 15px;
        &.spacing--big {
            margin-bottom: 30px;
        }
    }
    &.spacing--top {
        margin-top: 15px;
        &.spacing--big {
            margin-top: 30px;
        }
    }
}

.content-center {
    position: relative;
    left: 50%;
    @include translate(-50%, 0);
}

.row.row-eq-height {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;

    @include media-breakpoint-down(md) {
        display: block;
    }
}

.color-default {
    color: $grey-3;
}
.color-white {
    color: $white;
}
.color-blue {
    color: $blue;
}
.color-red {
    color: $red;
}
.color-grey-2 {
    color: $grey-2;
}

.color-new-red {
    color: $new-red;
}

.link-button {
    @include link-button;
}

.default-brick-space {
    padding-bottom: $brick-spacing-half;
}

.big-container {
    width: $big-container-width;
    margin: 0 auto;
    padding: 0 15px;
    max-width: 100%;
}

.btn {
    @include transition(background-color border color ease-in-out 500ms);

    &.btn-primary {
        color: $white;
        font-weight: $font-weight-bold;
        padding: 6px 20px;
    }

    &.btn-green {
        text-transform: uppercase;
        background-color: $green;
        border-radius: 0;
        border-color: $green;
        @include transition(all 500ms);

        &:hover {
            background-color: $white;
            color: $green;
            @include transition(all 500ms);
        }
    }

    &.btn-red {
        text-transform: uppercase;
        background-color: $red;
        border-radius: 0;
        border-color: $red;
        @include transition(all 500ms);

        &:hover {
            background-color: $white;
            color: $red;
            @include transition(all 500ms);
        }
    }

    &.btn-teaser {
        position: relative;
        background-color: $red;
        border: 1px solid $red;
        border-radius: 0;
        text-transform: uppercase;
        padding: 5px $default-space;
        color: $white;
        font-weight: $font-weight-bold;
        @include transition(all ease-in-out 300ms);

        &:hover {
            background-color: rgba($white, 0.8);
            color: $red;
            @include transition(all ease-in-out 300ms);
        }

        &:after {
            content: "\f101";
            display: inline-block;
            font-family: $fontfamily-fontawesome;
            padding-left: $default-space;
            font-weight: $fontweight-fontawesome;
        }


        &.color-blue { @include btnColors($blue, $white); }
        &.color-red { @include btnColors($red, $white); }
    }

    &.btn-color-teaser {
        position: relative;
        background-color: $red;
        border: 1px solid $white;
        border-radius: 0;
        text-transform: uppercase;
        padding: 5px $default-space;
        color: $white;
        font-weight: $font-weight-bold;
        @include transition(all ease-in-out 300ms);

        &:hover {
            background-color: rgba($white, 0.8);
            color: $red;
            @include transition(all ease-in-out 300ms);
        }

        &:after {
            content: "\f101";
            display: inline-block;
            font-family: $fontfamily-fontawesome;
            padding-left: $default-space;
            font-weight: $fontweight-fontawesome;
        }


        &.color-blue { @include btnColors($blue, $white); }
        &.color-red { @include btnColors($red, $white); }
    }

    &.btn-arrow {
        position: relative;

        &:before {
            content: "\203A";
            position: absolute;
            left: 0;
            top: 0;
            font-weight: bold;
            font-size: 1.4em;
            font-family: $fontfamily-fontawesome;
        }
    }

    &.btn-double-arrow {
        position: relative;

        &:after {
            content: "\f101";
            display: inline-block;
            font-family: $fontfamily-fontawesome;
            padding-left: $default-space;
            font-weight: $fontweight-fontawesome;

            @include media-breakpoint-down(sm) {
                padding-left: $small-space;
            }
        }
    }

    &.btn-arrow-down {
        position: relative;

        &:after {
            content: "\f0d7";
            display: inline-block;
            font-family: $fontfamily-fontawesome;
            padding-left: $default-space;
            font-weight: $fontweight-fontawesome;
        }
    }
}

.button-wrapper {
    margin-top: $big-space;
}

a.fullwidth-link {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 5;
}

// body editmode
body.editmode {
    header {
        position: static;
    }
}

.space-bottom {
    padding-bottom: $default-space;
}
.space-bottom--big {
    padding-bottom: $big-space;
}

span.icon > svg {
    width: 100%;
    height: 100%;
}

// bg colors
.bg-color--grey {
    background-color: $grey-0;
}

.bg-color--grey-dark {
    background-color: $grey-3;
    color: $white;

    .color-default {
        color: $white;
    }
}

.bg-color--red {
    background-color: $red;
}

.copy-s {
    @include font-copy-s;
}

.copy-m {
    @include font-copy-m;
}

.copy-m2 {
    @include font-copy-m2;
}

.copy-s-bold {
    @include font-copy-s;
    font-weight: bold;
}

.visible-inline-block {
    display: inline-block;
}

// list styles
ul {
    &.arrow-list, &.tick-list, &.double-arrow-list, &.long-arrow-list {
        @include faListClear;
    }

    &.green-links {
        @include greenList;
    }

    &.red-links {
        @include redList;
    }

    &.long-arrow-list {
        li {
            padding-left: $default-space + 5px;

            &:before {
                content:"\f178";
                font-family: $fontfamily-fontawesome;
                font-size: 0.7em;
                top: 6px;
                font-weight: $fontweight-fontawesome;
            }
        }
    }

    &.tick-list {
        li {
            padding-left: $default-space + 5px;
            border-top: 1px solid $grey-0;
            border-bottom: 1px solid $grey-0;
            padding-top: 10px;
            padding-bottom: 10px;

            &:before {
                content:"\f00c";
                font-family: $fontfamily-fontawesome;
                font-size: 0.7em;
                top: $default-space;
                font-weight: $fontweight-fontawesome;
            }
        }
    }

    &.double-arrow-list {
        li {
            padding-left: $default-space;

            &:before {
                content: "\f101";
                font-family: $fontfamily-fontawesome;
                font-size: 0.7em;
                top: 6px;
                font-weight: $fontweight-fontawesome;
            }
        }
    }

    &.big-link-list {
        list-style: none;
        padding-left: 0;
        margin-bottom: 0;

        li {
            a {
                color: $body-color;
                font-weight: $font-weight-bold;
                text-transform: uppercase;
                display: inline-block;
                padding-top: 10px;
                padding-bottom: 10px;
                border-top: 1px solid $grey-0;
                border-bottom: 1px solid $grey-0;

                &:before {
                    content: "\203A";
                    display: inline-block;
                    font-family: $fontfamily-fontawesome;
                    padding-right: $default-space;
                    font-size: 1.3em;
                    font-weight: $fontweight-fontawesome;
                }

                &:hover {
                    color: $green;

                    &:before {
                        color: $green;
                    }
                }
            }
        }
    }

    &.color-grey-2 {
        li:before {
            color: $grey-2;
        }
    }
}

form.custom-form {
    .brick {
        padding: 0;
    }

    .form-group {
        position: relative;
        margin-bottom: 30px;

        &.has-select {
            position: relative;

            &:before {
                content: "";
                display: block;
                width: 0;
                height: 0;
                border-style: solid;
                border-width: 10px 5px 0 5px;
                border-color: $grey-2 transparent transparent transparent;
                position: absolute;
                top: 50%;
                right: 10px;
                //@include translate(0,-50%);
            }
        }

        > label {
            display: block;
            position: absolute;
            left: 20px;
            margin-bottom: 0;
            padding: 0 15px;
            background-color: #fff;
            z-index: 1;
            top: 0;
            @include transform(translate(-15px, -50%) scale(0.75));
            //@include transform-origin(0 0);
            @include transition(transform 160ms,top .2s);

            &.inactive {
                top: 19px;
                @include transform(translate(-15px, -50%) scale(1.1));
            }

            &.type-radio {
                padding-left: 0;
                position: static;
                //@include translate(0, 0);
            }
        }
    }

    .radio, .checkbox {
        margin-top: 0;
    }

    .form-control {
        padding: 6px 12px;
        width: 100%;
        height: 40px;
        border: 1px solid $grey-1;
        border-radius: 0;
        box-shadow: none !important;
    }

    select.form-control {
        -webkit-appearance: none;
        -moz-appearance: none;
        background: transparent;
        position: relative;
        &::-ms-expand {
            display: none;
        }
    }

    input.form-control[type=file] {
        box-sizing: content-box;
        height: 24px;
        padding-top: 7px;
        padding-bottom: 7px;
        width: calc(100% - 26px);
    }

    textarea.form-control {
        resize: vertical;
        min-height: 100px;
    }

    [type="submit"] {
        @include button-variant($green, $white, $green);
        padding: 10px 20px;
        border-radius: 0;
        font-size: 16px;
    }
}


.grecaptcha-badge {
    visibility: hidden;
    &--visible {
        visibility: visible;
    }
}


#CybotCookiebotDialogBodyLevelButtonLevelOptinAllowallSelection
{
    background-color:#333333 !important;
    border-color:#333333 !important;
}

.headline-wrapper {
    position: relative;
    padding-bottom: $big-space;

    .area-headline__anchor {
        position: relative;
        top: -$header-height;
        visibility: hidden;
    }

    h6 {
        color: $red;
    }

    h1, .h1, h2, .h2, h3, .h3, h4, .h4, h5, .h5, h6, .h6 {
        margin-bottom: 0;

        & + .subline {
            margin-top: 7px;
        }
    }
}

.img-fluid {
    display: block;
    max-width: 100%;
    height: auto;
}

.b-wysiwyg__custom.btn-primary {
    a {
        @include transition(background-color border color ease-in-out 500ms);

        color: $white;
        font-weight: $font-weight-bold;
        padding: 6px 20px;
        text-transform: uppercase;
        background-color: $red;
        position: relative;
        text-decoration: none;
        border: 1px solid $red;
        line-height: 1.5;

        &:after {
            content: "\f101";
            display: inline-block;
            font-family: $fontfamily-fontawesome;
            padding-left: $default-space;
            font-weight: $fontweight-fontawesome;
        }

        &:hover {
            background-color: $white;
            color: $red;
            @include transition(all 500ms);
        }
    }
}

/**
 * Global Google Autocomplete Styles
 * Used in Booking App and Quick Search
 */
.pac-container {
    font-family: $font-family-base;

    .pac-item {
        padding: 16px;
        font-size: $font-size-xs;
        color: $grey-3;
        line-height: 16px;

        .pac-icon {
            display: none;
        }

        .pac-item-query {
            color: $grey-3;
            font-size: $font-size-xs;

            .pac-matched {
                font-weight: $font-weight-semibold;

                &:first-child {
                    font-weight: $font-weight-bold;
                }
            }
        }

        &:hover, &.pac-item-selected {
            background-color: $new-red;
            color: $white;
            cursor: pointer;

            .pac-item-query {
                color: $white;
            }
        }

        &.pac-item-selected {

        }
    }
}