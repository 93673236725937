
/* handlee-regular - latin */
@font-face {
    font-family: 'Handlee';
    font-style: normal;
    font-weight: 400;
    src: url('../fonts/handlee-v14-latin/handlee-v14-latin-regular.eot'); /* IE9 Compat Modes */
    src: local(''),
    url('../fonts/handlee-v14-latin/handlee-v14-latin-regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
    url('../fonts/handlee-v14-latin/handlee-v14-latin-regular.woff2') format('woff2'), /* Super Modern Browsers */
    url('../fonts/handlee-v14-latin/handlee-v14-latin-regular.woff') format('woff'), /* Modern Browsers */
    url('../fonts/handlee-v14-latin/handlee-v14-latin-regular.ttf') format('truetype'), /* Safari, Android, iOS */
    url('../fonts/handlee-v14-latin/handlee-v14-latin-regular.svg#Handlee') format('svg'); /* Legacy iOS */
}

/* rubik-300 - latin */
@font-face {
    font-family: 'Rubik';
    font-style: normal;
    font-weight: 300;
    src: url('../fonts/rubik-v21-latin/rubik-v21-latin-300.eot'); /* IE9 Compat Modes */
    src: local(''),
    url('../fonts/rubik-v21-latin/rubik-v21-latin-300.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
    url('../fonts/rubik-v21-latin/rubik-v21-latin-300.woff2') format('woff2'), /* Super Modern Browsers */
    url('../fonts/rubik-v21-latin/rubik-v21-latin-300.woff') format('woff'), /* Modern Browsers */
    url('../fonts/rubik-v21-latin/rubik-v21-latin-300.ttf') format('truetype'), /* Safari, Android, iOS */
    url('../fonts/rubik-v21-latin/rubik-v21-latin-300.svg#Rubik') format('svg'); /* Legacy iOS */
}
/* rubik-regular - latin */
@font-face {
    font-family: 'Rubik';
    font-style: normal;
    font-weight: 400;
    src: url('../fonts/rubik-v21-latin/rubik-v21-latin-regular.eot'); /* IE9 Compat Modes */
    src: local(''),
    url('../fonts/rubik-v21-latin/rubik-v21-latin-regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
    url('../fonts/rubik-v21-latin/rubik-v21-latin-regular.woff2') format('woff2'), /* Super Modern Browsers */
    url('../fonts/rubik-v21-latin/rubik-v21-latin-regular.woff') format('woff'), /* Modern Browsers */
    url('../fonts/rubik-v21-latin/rubik-v21-latin-regular.ttf') format('truetype'), /* Safari, Android, iOS */
    url('../fonts/rubik-v21-latin/rubik-v21-latin-regular.svg#Rubik') format('svg'); /* Legacy iOS */
}
/* rubik-500 - latin */
@font-face {
    font-family: 'Rubik';
    font-style: normal;
    font-weight: 500;
    src: url('../fonts/rubik-v21-latin/rubik-v21-latin-500.eot'); /* IE9 Compat Modes */
    src: local(''),
    url('../fonts/rubik-v21-latin/rubik-v21-latin-500.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
    url('../fonts/rubik-v21-latin/rubik-v21-latin-500.woff2') format('woff2'), /* Super Modern Browsers */
    url('../fonts/rubik-v21-latin/rubik-v21-latin-500.woff') format('woff'), /* Modern Browsers */
    url('../fonts/rubik-v21-latin/rubik-v21-latin-500.ttf') format('truetype'), /* Safari, Android, iOS */
    url('../fonts/rubik-v21-latin/rubik-v21-latin-500.svg#Rubik') format('svg'); /* Legacy iOS */
}

/* source-sans-pro-300 - latin */
@font-face {
    font-family: 'Source Sans Pro';
    font-style: normal;
    font-weight: 300;
    src: url('../fonts/source-sans-pro-v21-latin/source-sans-pro-v21-latin-300.eot'); /* IE9 Compat Modes */
    src: local(''),
    url('../fonts/source-sans-pro-v21-latin/source-sans-pro-v21-latin-300.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
    url('../fonts/source-sans-pro-v21-latin/source-sans-pro-v21-latin-300.woff2') format('woff2'), /* Super Modern Browsers */
    url('../fonts/source-sans-pro-v21-latin/source-sans-pro-v21-latin-300.woff') format('woff'), /* Modern Browsers */
    url('../fonts/source-sans-pro-v21-latin/source-sans-pro-v21-latin-300.ttf') format('truetype'), /* Safari, Android, iOS */
    url('../fonts/source-sans-pro-v21-latin/source-sans-pro-v21-latin-300.svg#SourceSansPro') format('svg'); /* Legacy iOS */
}
/* source-sans-pro-regular - latin */
@font-face {
    font-family: 'Source Sans Pro';
    font-style: normal;
    font-weight: 400;
    src: url('../fonts/source-sans-pro-v21-latin/source-sans-pro-v21-latin-regular.eot'); /* IE9 Compat Modes */
    src: local(''),
    url('../fonts/source-sans-pro-v21-latin/source-sans-pro-v21-latin-regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
    url('../fonts/source-sans-pro-v21-latin/source-sans-pro-v21-latin-regular.woff2') format('woff2'), /* Super Modern Browsers */
    url('../fonts/source-sans-pro-v21-latin/source-sans-pro-v21-latin-regular.woff') format('woff'), /* Modern Browsers */
    url('../fonts/source-sans-pro-v21-latin/source-sans-pro-v21-latin-regular.ttf') format('truetype'), /* Safari, Android, iOS */
    url('../fonts/source-sans-pro-v21-latin/source-sans-pro-v21-latin-regular.svg#SourceSansPro') format('svg'); /* Legacy iOS */
}
/* source-sans-pro-600 - latin */
@font-face {
    font-family: 'Source Sans Pro';
    font-style: normal;
    font-weight: 600;
    src: url('../fonts/source-sans-pro-v21-latin/source-sans-pro-v21-latin-600.eot'); /* IE9 Compat Modes */
    src: local(''),
    url('../fonts/source-sans-pro-v21-latin/source-sans-pro-v21-latin-600.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
    url('../fonts/source-sans-pro-v21-latin/source-sans-pro-v21-latin-600.woff2') format('woff2'), /* Super Modern Browsers */
    url('../fonts/source-sans-pro-v21-latin/source-sans-pro-v21-latin-600.woff') format('woff'), /* Modern Browsers */
    url('../fonts/source-sans-pro-v21-latin/source-sans-pro-v21-latin-600.ttf') format('truetype'), /* Safari, Android, iOS */
    url('../fonts/source-sans-pro-v21-latin/source-sans-pro-v21-latin-600.svg#SourceSansPro') format('svg'); /* Legacy iOS */
}
/* source-sans-pro-700 - latin */
@font-face {
    font-family: 'Source Sans Pro';
    font-style: normal;
    font-weight: 700;
    src: url('../fonts/source-sans-pro-v21-latin/source-sans-pro-v21-latin-700.eot'); /* IE9 Compat Modes */
    src: local(''),
    url('../fonts/source-sans-pro-v21-latin/source-sans-pro-v21-latin-700.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
    url('../fonts/source-sans-pro-v21-latin/source-sans-pro-v21-latin-700.woff2') format('woff2'), /* Super Modern Browsers */
    url('../fonts/source-sans-pro-v21-latin/source-sans-pro-v21-latin-700.woff') format('woff'), /* Modern Browsers */
    url('../fonts/source-sans-pro-v21-latin/source-sans-pro-v21-latin-700.ttf') format('truetype'), /* Safari, Android, iOS */
    url('../fonts/source-sans-pro-v21-latin/source-sans-pro-v21-latin-700.svg#SourceSansPro') format('svg'); /* Legacy iOS */
}


/* permanent-marker-regular - latin */
@font-face {
    font-family: 'Permanent Marker';
    font-style: normal;
    font-weight: 400;
    src: url('../fonts/permanent-marker-v16-latin/permanent-marker-v16-latin-regular.eot'); /* IE9 Compat Modes */
    src: local(''),
    url('../fonts/permanent-marker-v16-latin/permanent-marker-v16-latin-regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
    url('../fonts/permanent-marker-v16-latin/permanent-marker-v16-latin-regular.woff2') format('woff2'), /* Super Modern Browsers */
    url('../fonts/permanent-marker-v16-latin/permanent-marker-v16-latin-regular.woff') format('woff'), /* Modern Browsers */
    url('../fonts/permanent-marker-v16-latin/permanent-marker-v16-latin-regular.ttf') format('truetype'), /* Safari, Android, iOS */
    url('../fonts/permanent-marker-v16-latin/permanent-marker-v16-latin-regular.svg#PermanentMarker') format('svg'); /* Legacy iOS */
}


/* nunito-300 - latin */
@font-face {
    font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
    font-family: 'Nunito';
    font-style: normal;
    font-weight: 300;
    src: url('../fonts/nunito-v26-latin/nunito-v26-latin-300.eot'); /* IE9 Compat Modes */
    src: url('../fonts/nunito-v26-latin/nunito-v26-latin-300.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
    url('../fonts/nunito-v26-latin/nunito-v26-latin-300.woff2') format('woff2'), /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
    url('../fonts/nunito-v26-latin/nunito-v26-latin-300.woff') format('woff'), /* Chrome 5+, Firefox 3.6+, IE 9+, Safari 5.1+, iOS 5+ */
    url('../fonts/nunito-v26-latin/nunito-v26-latin-300.ttf') format('truetype'); /* Chrome 4+, Firefox 3.5+, IE 9+, Safari 3.1+, iOS 4.2+, Android Browser 2.2+ */
}


/* nunito-regular - latin */
@font-face {
    font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
    font-family: 'Nunito';
    font-style: normal;
    font-weight: 400;
    src: url('../fonts/nunito-v26-latin/nunito-v26-latin-regular.eot'); /* IE9 Compat Modes */
    src: url('../fonts/nunito-v26-latin/nunito-v26-latin-regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
    url('../fonts/nunito-v26-latin/nunito-v26-latin-regular.woff2') format('woff2'), /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
    url('../fonts/nunito-v26-latin/nunito-v26-latin-regular.woff') format('woff'), /* Chrome 5+, Firefox 3.6+, IE 9+, Safari 5.1+, iOS 5+ */
    url('../fonts/nunito-v26-latin/nunito-v26-latin-regular.ttf') format('truetype'); /* Chrome 4+, Firefox 3.5+, IE 9+, Safari 3.1+, iOS 4.2+, Android Browser 2.2+ */
}


/* nunito-500 - latin */
@font-face {
    font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
    font-family: 'Nunito';
    font-style: normal;
    font-weight: 500;
    src: url('../fonts/nunito-v26-latin/nunito-v26-latin-500.eot'); /* IE9 Compat Modes */
    src: url('../fonts/nunito-v26-latin/nunito-v26-latin-500.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
    url('../fonts/nunito-v26-latin/nunito-v26-latin-500.woff2') format('woff2'), /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
    url('../fonts/nunito-v26-latin/nunito-v26-latin-500.woff') format('woff'), /* Chrome 5+, Firefox 3.6+, IE 9+, Safari 5.1+, iOS 5+ */
    url('../fonts/nunito-v26-latin/nunito-v26-latin-500.ttf') format('truetype'); /* Chrome 4+, Firefox 3.5+, IE 9+, Safari 3.1+, iOS 4.2+, Android Browser 2.2+ */
}


/* nunito-600 - latin */
@font-face {
    font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
    font-family: 'Nunito';
    font-style: normal;
    font-weight: 600;
    src: url('../fonts/nunito-v26-latin/nunito-v26-latin-600.eot'); /* IE9 Compat Modes */
    src: url('../fonts/nunito-v26-latin/nunito-v26-latin-600.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
    url('../fonts/nunito-v26-latin/nunito-v26-latin-600.woff2') format('woff2'), /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
    url('../fonts/nunito-v26-latin/nunito-v26-latin-600.woff') format('woff'), /* Chrome 5+, Firefox 3.6+, IE 9+, Safari 5.1+, iOS 5+ */
    url('../fonts/nunito-v26-latin/nunito-v26-latin-600.ttf') format('truetype'); /* Chrome 4+, Firefox 3.5+, IE 9+, Safari 3.1+, iOS 4.2+, Android Browser 2.2+ */
}


/* nunito-700 - latin */
@font-face {
    font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
    font-family: 'Nunito';
    font-style: normal;
    font-weight: 700;
    src: url('../fonts/nunito-v26-latin/nunito-v26-latin-700.eot'); /* IE9 Compat Modes */
    src: url('../fonts/nunito-v26-latin/nunito-v26-latin-700.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
    url('../fonts/nunito-v26-latin/nunito-v26-latin-700.woff2') format('woff2'), /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
    url('../fonts/nunito-v26-latin/nunito-v26-latin-700.woff') format('woff'), /* Chrome 5+, Firefox 3.6+, IE 9+, Safari 5.1+, iOS 5+ */
    url('../fonts/nunito-v26-latin/nunito-v26-latin-700.ttf') format('truetype'); /* Chrome 4+, Firefox 3.5+, IE 9+, Safari 3.1+, iOS 4.2+, Android Browser 2.2+ */
}


/* nunito-800 - latin */
@font-face {
    font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
    font-family: 'Nunito';
    font-style: normal;
    font-weight: 800;
    src: url('../fonts/nunito-v26-latin/nunito-v26-latin-800.eot'); /* IE9 Compat Modes */
    src: url('../fonts/nunito-v26-latin/nunito-v26-latin-800.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
    url('../fonts/nunito-v26-latin/nunito-v26-latin-800.woff2') format('woff2'), /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
    url('../fonts/nunito-v26-latin/nunito-v26-latin-800.woff') format('woff'), /* Chrome 5+, Firefox 3.6+, IE 9+, Safari 5.1+, iOS 5+ */
    url('../fonts/nunito-v26-latin/nunito-v26-latin-800.ttf') format('truetype'); /* Chrome 4+, Firefox 3.5+, IE 9+, Safari 3.1+, iOS 4.2+, Android Browser 2.2+ */
}
